<template>
  <v-dialog v-model="ObjectList" scrollable max-width="880px">
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar color="primary" class="mx-0 px-0 my-0 py-0" fixed top dark>
          <v-icon @click="ObjectList = false">mdi-close</v-icon>
          <!--Überschrift-->
          <v-toolbar-title class="flex text-center">Objekte</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mx-0 px-0 my-0 py-0">
        <v-simple-table>
          <tbody>
            <v-data-table
              ref="vuetable"
              :headers="header"
              :items="objects"
              :items-per-page="10"
              class="elevation-1"
              ><template v-slot:no-data> Keine Daten gefunden. </template
              ><template v-slot:item.actions="{ item }">
                <v-btn
                  fab
                  small
                  text
                  elevation="2"
                  class="mr-2 col-xl-5"
                  @click="addObject(item)"
                >
                  <v-icon small> mdi-plus </v-icon>
                </v-btn>
              </template>
              ></v-data-table
            >
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="ObjectList = false">Fertig</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    objects: [],
    ObjectList: false,
    header: [
      { text: 'Code', value: 'key.code', align: 'start' },
      { text: 'Beschreibung', value: 'description', align: 'left' },
      { text: 'Kommentar', value: 'comment', align: 'left' },
      { text: 'Besitzer', value: 'owner', align: 'left' },
      {
        text: 'Hinzufügen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ]
  }),
  methods: {
    open(objects) {
      this.objects = objects
      this.ObjectList = true
    },
    addObject(item) {
      this.$emit('addObject', item)
      this.objects.splice(this.objects.indexOf(item), 1)
    }
  }
}
</script>

<style></style>
