<template>
  <v-app style='font-family: "Helvetica Neue LT Std"'>
    <Header :drawer="drawer" @drawerChange="drawerChange" />
    <NavBar v-if="loggedIn" :drawer="drawer" @drawerChange="drawerChange" />
    <v-main class="white">
      <!-- <BreadCrumbComponent v-if="showBreadCrumb" :key="this.$route.fullPath" /> -->
      <router-view></router-view>
    </v-main>
    <Footer v-if="!isMobile()"></Footer>
  </v-app>
</template>

<script>
import Header from './components/core/Header'
import NavBar from './components/core/NavBar.vue'
import Footer from './components/core/Footer'
import { mapGetters } from 'vuex'
//import BreadCrumbComponent from '@/components/core/BreadCrumbComponent.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    NavBar
    //BreadCrumbComponent
  },
  data: () => ({
    settings: {
      default: null
    },
    drawer: false
  }),

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    drawerChange(newVal) {
      this.drawer = newVal
    }
  },
  computed: {
    ...mapGetters(['loggedIn']),
    showBreadCrumb() {
      if (
        this.$route.path == '/' ||
        this.$route.path == '/cal' ||
        this.$route.path == '/login' ||
        this.$route.path == '/calv2'
      ) {
        return false
      }
      return true
    }
  },

  // In deiner Hauptkomponente oder einem anderen geeigneten Ort
  mounted() {
    // falls User die Seite refreshed und ein Token gespeichert ist, wird dieser Token für zukünftige requests benutzt
    this.$store.dispatch('loadAuthFromLocalStorage')

    const checkAppVersionAndClearCache = async () => {
      const getVersionFromCache = async () => {
        return new Promise(resolve => {
          const cachedVersion = localStorage.getItem('appVersion')
          resolve(cachedVersion)
        })
      }

      const getCurrentVersion = async () => {
        return new Promise(resolve => {
          const currentVersion = process.env.VUE_APP_GIT_HASH || 'n/a'
          resolve(currentVersion)
        })
      }

      const clearBrowserCache = async () => {
        return new Promise(resolve => {
          if ('caches' in window) {
            caches.keys().then(cacheNames => {
              cacheNames.forEach(cacheName => {
                caches.delete(cacheName)
              })
              resolve()
            })
          } else {
            resolve()
          }
        })
      }

      const updateAppVersionInCache = async version => {
        return new Promise(resolve => {
          localStorage.setItem('appVersion', version)
          resolve()
        })
      }

      const cachedVersion = await getVersionFromCache()
      const currentVersion = await getCurrentVersion()

      if (cachedVersion !== currentVersion) {
        await clearBrowserCache()
        await updateAppVersionInCache(currentVersion)
      }
    }

    checkAppVersionAndClearCache()
    this.$store.dispatch('loadUserSettings')
  }
}
</script>

<style lang="scss">
.v-application {
  [class*='text-'] {
    font-family: 'HelveticaNeueLTStd-Roman', sans-serif !important;
  }
  [class*='header-'] {
    font-family: 'HelveticaNeueLTStd-Md', sans-serif !important;
  }
  font-family: 'HelveticaNeueLTStd-Roman', sans-serif !important;
}
.centerElement {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c8c6d2 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c8c6d2;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
.scrollable-content {
  height: 100%;
}
.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}

.border-left-primary-3 {
  border-left: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}

.border-left-primary-5 {
  border-left: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}

.border-bottom-primary-5 {
  border-bottom: 5px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
.border-bottom-primary-3 {
  border-bottom: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
</style>
