<template>
  <div>
    <v-navigation-drawer
      app
      v-model="isNavbarOpen"
      :permanent="!miniNavbar"
      :temporary="miniNavbar"
      mobile-breakpoint="md"
      class="elevation-0 d-flex flex-column "
    >
      <div>
        <v-list nav dense>
          <v-list-item-group v-model="group">
            <v-list-item :to="{ name: 'Home' }" class="mx-3 pa-2 my-5">
              <img
                src="@/assets/LBA-Logo-Text.svg"
                alt="LBA-WEB"
                class="pa-3"
                style="max-width: 150px"
              />
            </v-list-item>

            <v-divider class="mx-4"></v-divider>

            <div class="my-5 mx-3 pa-2" v-if="lbaApps.length != 0">
              <h5 v-if="!miniNavbar" class="text-uppercase pb-2 primary--text">
                LBA-Apps
              </h5>
              <!-- Alle Apps anzeigen -->
              <div v-for="(app, i) in lbaApps" :key="i">
                <v-list-item
                  exact
                  :to="app.appUrl"
                  :disabled="!app.hasPermission"
                  class="primary--text li-item"
                  active-class="selected-li-item"
                  v-if="app.appBezeichnung !== 'Statistik'"
                >
                  <v-list-item-avatar>
                    <img :src="appImg(app)" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="app.appBezeichnung"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group
                  v-if="app.appBezeichnung === 'Statistik'"
                  class="primary--text"
                  active-class="primary--text"
                >
                  <template v-slot:activator class="primary--text">
                    <v-list-item-avatar>
                      <img :src="appImg(app)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="primary--text"
                        v-text="app.appBezeichnung"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    :to="'/bi/revenue'"
                    class="primary--text li-item pl-6"
                    active-class="selected-li-item"
                  >
                    <v-list-item-avatar>
                      <img :src="appImg(app)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Umsatz'"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    :to="'/bi/keyfigures'"
                    class="primary--text li-item pl-6"
                    active-class="selected-li-item"
                  >
                    <v-list-item-avatar>
                      <img :src="appImg(app)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="'Kennzahlen'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </div>
            </div>

            <v-divider class="mx-4"></v-divider>

            <div class="my-5 mb-8 mx-3 pa-2" v-if="lbaSettingsApps.length != 0">
              <h5
                v-if="!miniNavbar"
                class="text-uppercase pb-2 pl-2 primary--text"
              >
                LBA-Einstellungen
              </h5>
              <v-divider v-else></v-divider>

              <!-- Alle Apps anzeigen -->
              <v-list-item
                v-for="(app, i) in getSettingsAppsWithoutSettings()"
                :key="i"
                exact
                :to="app.appUrl"
                :disabled="!app.hasPermission"
                class="primary--text li-item"
                active-class="selected-li-item"
              >
                <v-list-item-avatar>
                  <img :src="appImg(app)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="app.appBezeichnung"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-group v-if="settingsAppExists()" color="white">
                <template v-slot:activator class="primary--text">
                  <v-list-item-avatar>
                    <img :src="appImg(settingsApp)" />
                  </v-list-item-avatar>
                  <v-list-item-content class="primary--text">
                    <v-list-item-title
                      v-text="settingsApp.appBezeichnung"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(app, i) in getAppsWithSettings()"
                  :key="i"
                  exact
                  :to="'/settings' + app.appUrl"
                  :disabled="!app.hasPermission"
                  class="primary--text li-item pl-6"
                  active-class="selected-li-item"
                >
                  <v-list-item-avatar>
                    <img :src="appImg(app)" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="app.appBezeichnung"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
          </v-list-item-group>
        </v-list>
      </div>
      <template v-slot:append>
        <v-divider class="mx-4"></v-divider>
        <v-container
          class="d-flex flex-column align-center font-weight-bold my-2 "
        >
          <VersionChip />
        </v-container>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import statisticsLogo from '@/assets/svg/lba-apps/LBA-Statistik.svg'
import calLogo from '@/assets/svg/lba-apps/LBA-Kalender.svg'
import crmLogo from '@/assets/svg/lba-apps/LBA-CRM.svg'
import devLogo from '@/assets/svg/lba-apps/LBA-DEV.svg'
import artikelLogo from '@/assets/svg/lba-apps/LBA-ArticleSearch.svg'
import businessLogo from '@/assets/svg/lba-apps/LBA-Business.svg'
import taskLogo from '@/assets/svg/lba-apps/LBA-Task.svg'
import settingsLogo from '@/assets/svg/lba-apps/LBA-Settings.svg'
import VersionChip from './VersionChip.vue'

import NotificationService from '@/services/notifications/notifications'
import telemetryService from '@/services/core/telemetryService'

export default {
  components: {
    VersionChip
  },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    group: null,
    homeAppString: 'LBA-WEB',
    isNavbarOpen: false
  }),
  computed: {
    ...mapGetters([
      'loggedIn',
      'user',
      'user_img_base64',
      'lbaApps',
      'lbaSettingsApps',
      'availableApps'
    ]),

    settingsApp() {
      return this.lbaSettingsApps.filter(app => app.appId === 10)[0]
    },
    miniNavbar() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm' ||
        this.$vuetify.breakpoint.name == 'md'
      ) {
        return true
      } else {
        return false
      }
    },
    currentApplication() {
      // checks which lba-app matches the current router path. Reporting is outsourced in a watcher below.

      if (this.$route.path <= 1) return undefined

      let routerSplitString = this.$route.path.substring(1).split('/')

      return this.availableApps.find(
        app => routerSplitString[0] == app.appUrl.substring(1)
      )
    },
    loginButtonVisible() {
      if (!this.loggedIn && this.$route.name != 'Login') {
        return true
      }
      return false
    }
  },

  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.name == 'sm') {
        return true
      } else {
        return false
      }
    },
    logout() {
      this.$store.dispatch('logout')
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true
      }
      return false
    },
    appImg(item) {
      if (item.appBezeichnung == 'Statistik') {
        return statisticsLogo
      } else if (
        item.appBezeichnung == 'Kalender' ||
        item.appBezeichnung == 'Kalender-v2'
      ) {
        return calLogo
      } else if (item.appBezeichnung == 'CRM') {
        return crmLogo
      } else if (item.appBezeichnung == 'Entwicklerportal') {
        return devLogo
      } else if (item.appBezeichnung == 'Artikel') {
        return artikelLogo
      } else if (item.appBezeichnung == 'Business') {
        return businessLogo
      } else if (item.appBezeichnung == 'Einstellungen') {
        return settingsLogo
      } else if (item.appBezeichnung == 'Task') {
        return taskLogo
      }
      return require('@/assets/LBA_logo_big.png')
    },
    navigateToHome() {
      this.$router.push('/')
    },
    getSettingsAppsWithoutSettings() {
      return this.lbaSettingsApps.filter(app => app.appId !== 10)
    },
    settingsAppExists() {
      return this.lbaSettingsApps.filter(app => app.appId == 10).length !== 0
        ? true
        : false
    },
    getAppsWithSettings() {
      return this.lbaApps.filter(app => app.appId == 1 || app.appId == 2)
    }
  },
  watch: {
    drawer(newVal) {
      this.$emit('drawerChange', newVal)
      this.isNavbarOpen = newVal
    },
    isNavbarOpen(newVal) {
      if (this.drawer !== newVal) this.$emit('drawerChange', newVal)
    },
    async currentApplication(newApp, oldApp) {
      if (
        newApp != undefined &&
        JSON.stringify(newApp) != JSON.stringify(oldApp)
      ) {
        telemetryService.reportAppAccessById(newApp.appId)
      }
    }
  },
  mounted() {
    this.$store.dispatch('loadUserData')
    this.$store.dispatch('loadUserImageBase64')
    this.$store.dispatch('loadUserSettings')
    this.$store.dispatch('loadVisibleTabs')
    this.$store.dispatch('loadAppsAvailableForCurrentUser')

    this.isNavbarOpen = this.drawer

    NotificationService.initialize(this.$OneSignal)

    this.unwatch = this.$store.watch(
      (state, getters) => getters.loggedIn,
      newValue => {
        if (newValue == true) {
          this.$store.dispatch('loadUserData')
          this.$store.dispatch('loadUserImageBase64')
          this.$store.dispatch('loadUserSettings')
          NotificationService.initialize(this.$OneSignal)
        } else {
          this.$store.dispatch('clearUserData')
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  }
}
</script>

<style>
.selected-li-item {
  border-left: 3px solid;
  margin-left: -3px;
}
</style>
