var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"value":_vm.showNotificationsMenu,"close-on-content-click":false,"nudge-width":200,"max-width":_vm.getMenuSize(),"max-height":"75%","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',_vm._g(_vm._b({staticClass:"rounded-pill fluid mx-3 pa-2",class:{ hoveredIcon: hover },on:{"click":function($event){return _vm.askForNotificationPermission()}}},'div',attrs,false),on),[(_vm.getAmountOfUnreadNotifications() == 0)?_c('v-icon',[_vm._v("mdi-bell")]):_c('v-badge',{attrs:{"color":"orange","content":_vm.getAmountOfUnreadNotifications() > 9
              ? '9+'
              : _vm.getAmountOfUnreadNotifications(),"overlap":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}],null,true)})]}}])},[_c('v-card',{staticClass:"notification-container menu"},[(
        this.waitForAnswerOfNotificationPermission == false &&
          _vm.getAmountOfUnreadNotifications() != 0
      )?_c('v-row',[_c('v-col',{staticClass:"my-2 p-2 mb-n4 d-flex justify-center font-weight-black",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.getAmountOfUnreadNotifications())+" ungelesene Nachrichten ")]),_c('v-container',_vm._l((this.$store.state.notifications
            .notifications),function(notification){return _c('div',{key:notification.nId},[(notification.read == false)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-divider',{attrs:{"inset":notification.inset}}),_c('v-col',{staticClass:"px-6 mx-lg-auto",class:{
                  selectedNotification: hover
                },attrs:{"cols":"12"},on:{"click":function($event){return _vm.openNotification(notification)}}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"10"}},[_c('v-col',{staticClass:"pa-0 ma-0 font-weight-black"},[_vm._v(" "+_vm._s(notification.subject)+" ")]),_c('v-col',{staticClass:"pa-0 ma-0 mb-1 text--secondary font-weight-black",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(notification.a_dat)+" | "+_vm._s(notification.fromUserId)+" ")])],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"2","align":"right"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{class:{ hoveredIcon: hover },attrs:{"fab":"","small":"","plain":""},on:{"click":[function($event){$event.stopPropagation();$event.preventDefault();},function($event){return _vm.setNotificationToRead(notification)}]}},[_c('v-icon',[_vm._v(" mdi-email-check ")])],1)]}}],null,true)})],1)],1),_c('v-col',{staticClass:"pa-0 ma-0"},[_vm._v(_vm._s(notification.message))])],1)],1)]}}],null,true)}):_vm._e()],1)}),0)],1):_vm._e(),(
        this.waitForAnswerOfNotificationPermission == true ||
          _vm.getAmountOfUnreadNotifications() == 0
      )?_c('v-row',[_c('v-col',{staticClass:"mx-6 mt-4 mb-0"},[_c('v-icon',{staticClass:"d-flex justify-content-center",attrs:{"size":"80"}},[_vm._v(" mdi-bell ")]),_c('v-col',{staticClass:"d-flex justify-content-center text-center"},[_vm._v(" "+_vm._s(_vm.getErrorMessage())+" ")])],1)],1):_vm._e(),_c('v-col',{attrs:{"align":"center"}},[(!this.waitForAnswerOfNotificationPermission)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.openNotificationTable()}}},[_vm._v(" "+_vm._s(_vm.getButtonText())+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }