import axios from 'axios'

export default {
  initialize,
  registerDeviceIdWithCurrentUser
}

function initialize(OneSignalInstance) {
  axios.get('/v1/notifications/onesignal/appId').then(res => {
    let appId = res.data

    OneSignalInstance.init({ appId: appId })

    OneSignalInstance.on('subscriptionChange', isSubscribed => {
      if (isSubscribed) {
        OneSignalInstance.getUserId(function(userId) {
          saveDeviceIdToLocalStorage(userId)
        })
      }
    })
  })
}

function registerDeviceIdWithCurrentUser() {
  try {
    let deviceId = loadDeviceIdFromLocalStorage()

    axios
      .put('/v1/notifications/onesignal/registration', { deviceId })
      .then(() => {
        console.log('Push Notifications aktiviert.')
      })
  } catch {
    return null
  }
}

function saveDeviceIdToLocalStorage(deviceId) {
  localStorage.setItem('notificationDeviceId', deviceId)
  registerDeviceIdWithCurrentUser()
}

function loadDeviceIdFromLocalStorage() {
  return localStorage.getItem('notificationDeviceId')
}
