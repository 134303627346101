import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify)
import de from 'vuetify/lib/locale/de'

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#13a0cb',
        secondary: '#004994',
        accent: '#8C345B',
        error: '#eb0050',
        succesS: '#78be00'
      }
    },
    typography: {
      fontFamily: 'HelveticaNeueLTStd-Roman'
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
})
