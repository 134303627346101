import axios from 'axios'

export default {
  state: {
    activeWidgets: []
  },
  mutations: {
    SET_ACTIVE_WIDGETS(state, newState) {
      state.activeWidgets = newState
    }
  },
  actions: {
    loadUserWidgets({ commit }) {
      return axios.get('/v1/dashboard').then(response => {
        commit('SET_ACTIVE_WIDGETS', response.data)
      })
    }
    /*setUserWidgets({ commit }, widgetArray) {
      return axios.put('/v1/dashboard', widgetArray).then(() => {
        commit('SET_ACTIVE_WIDGETS', widgetArray)
      })
    }*/
  },
  getters: {
    activeWidgets(state) {
      return state.activeWidgets
    }
  }
}
