export default {
  getBearerFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem('auth')).bearer
    } catch (e) {
      return null
    }
  },

  getRefreshTokenFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem('auth')).refreshToken
    } catch (e) {
      return null
    }
  }
}
