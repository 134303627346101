<template>
  <v-tab-item>
    <h3 class="mt-3 ml-2">{{ title }}</h3>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      outlined
      class="mb-4 px-2"
    ></v-text-field>
    <v-data-table
      ref="vuetable"
      :headers="pictureHeaders"
      :items="items"
      :items-per-page="10"
      :search="search"
    >
      <template v-slot:no-data> Keine Daten gefunden. </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.picBase != ''" v-viewer class="images clearfix">
          <v-btn
            fab
            small
            text
            elevation="2"
            class="mr-2 col-xl-5"
            @click="show(item)"
            ><v-icon small>mdi-eye</v-icon>
          </v-btn>
        </div>
        <div v-else v-viewer class="images clearfix">
          <v-btn
            disabled
            fab
            small
            text
            elevation="2"
            class="mr-2 col-xl-5"
            @click="show(item)"
            ><v-icon small>mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-tab-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array
    }
  },

  data: () => ({
    pictureHeaders: [
      { text: 'Dateiname', value: 'picName', align: 'start' },
      { text: 'aDat', value: 'aDat', align: 'center' },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ],
    search: null
  }),

  methods: {
    show(image) {
      //geklicktes Bild wird von aktueller Position entfernt
      var index = this.items.indexOf(image)
      if (index > -1) {
        this.items.splice(index, 1)
      }

      //geklicktes Bild wird als erstes Element in Liste gespeichert
      this.items.unshift(image)

      //Bilder in seperates Array extrahieren
      var displayedPics = []

      this.items.forEach(element => {
        displayedPics.push('data:image/png;base64,' + element.picBase)
      })

      //Liste wird angezeigt
      this.$viewerApi({
        images: displayedPics
      })
    }
  }
}
</script>

<style></style>
