<template>
  <v-dialog v-model="opened" persistent max-width="620">
    <v-card>
      <v-toolbar color="primary" dark class="mb-10">
        <!-- Button zum Schließen des Dialogs -->
        <v-icon @click="closeDialog()">mdi-close</v-icon>
        <v-toolbar-title class="flex text-center">
          Bild Hochladen
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <h5>Bild Hochladen zur Adresse:</h5>
        <h3>
          {{ address.name }}
        </h3>

        <h5 class="mt-8">Art des Bildes:</h5>
        <v-radio-group v-model="imageType" row class="mb-8">
          <v-radio :label="picTypes[0]" value="0"></v-radio>
          <v-radio :label="picTypes[1]" value="1"></v-radio>
          <v-radio :label="picTypes[2]" value="2"></v-radio>
          <v-radio :label="picTypes[3]" value="3"></v-radio>
          <v-radio :label="picTypes[4]" value="4"></v-radio>
        </v-radio-group>

        <label class="custom-file-upload">
          <input
            type="file"
            @change="changePrev"
            ref="file"
            id="file"
            accept="image/x-png,image/gif,image/jpeg"
            v-on:change="handleFileUpload()"
          />
          <v-icon>mdi-camera-plus</v-icon>
          <span class="pt-8 mx-2">Bild hinzufügen</span>
        </label>

        <div id="preview" v-if="img_prevurl != ''">
          <h3 class="mt-8 mb-2">Vorschau:</h3>

          <img :src="img_prevurl" />
        </div>
        <v-row class="mt-3 mb-1">
          <v-btn
            v-if="address != null && img_prevurl != ''"
            @click="submitFile()"
            color="green darken-1"
            class="white--text ml-3"
            >Hochladen <v-icon class="ml-2">mdi-upload</v-icon></v-btn
          >
          <v-btn
            @click="img_prevurl = ''"
            plain
            v-if="img_prevurl != ''"
            color="red"
            class="ml-4 pl-0"
            >Entfernen</v-btn
          >
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loadingPicUpload == true"
            class="ml-5"
          ></v-progress-circular>
        </v-row>
      </v-card-text>
    </v-card>

    <ErrorDialog
      :failMessage="failMessage"
      :opened="ErrorPictureUpload"
      @closeDialog="ErrorPictureUpload = false"
    />

    <!-- Foto erfolgreich hochgeladen snackbar -->
    <v-snackbar v-model="SuccessSnackbarPicture">
      Foto wurde hochgeladen

      <template v-slot:action="{ attrs }">
        <v-btn
          color="green darken-1"
          text
          v-bind="attrs"
          @click="SuccessSnackbarPicture = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import ErrorDialog from '@/components/core/ErrorDialog.vue'

export default {
  components: {
    ErrorDialog
  },

  props: { address: Object, picTypes: Array, appointmentKey: Object },
  data: () => ({
    opened: true,

    imageType: '0',
    img_prevurl: [],
    loadingPicUpload: false,
    ErrorPictureUpload: false,
    SuccessSnackbarPicture: false,
    errorDialog: false,

    failMessage: 'Bilderupload fehlgeschlagen!'
  }),
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
    },
    changePrev(e) {
      const file = e.target.files[0]
      this.img_prevurl = URL.createObjectURL(file)
    },
    handleFileUpload() {
      let file = this.$refs.file.files[0]
      this.formDataFile = new FormData()
      this.formDataPicType = new FormData()
      this.formDataFile.append('file', file)
      this.formDataPicType.append('picType', this.imageType)
    },
    async submitFile() {
      this.handleFileUpload()
      this.loadingPicUpload = true
      var formDataFile = this.formDataFile

      //TODO: In appointment.js File verlagern
      var uploadResponse = null
      try {
        uploadResponse = await axios.post(
          '/v1/cal/addresses/pictures/upload',
          formDataFile,
          {
            headers: {
              ImageType: this.imageType
            }
          }
        )
        console.log(uploadResponse)
        this.img_prevurl = ''
        this.loadingPicUpload = false
        this.SuccessSnackbarPicture = true

        // Lädt Namen des Bildes Hoch
        let picNames = []
        picNames.push(uploadResponse.data.picName)
        this.address.picNames = [...picNames]

        this.$store
          .dispatch('uploadImageNames', {
            key: {
              lfdnr: this.address.lfdnr ?? this.address.key.lfdnr,
              firma: 1
            },
            picNames: this.address.picNames,
            picType: this.imageType,
            appointmentKey: this.appointmentKey
          })
          .then(response => {
            //alert('successfully uploaded ')
            this.$emit('picture-uploaded')
            console.log(response)
          })
      } catch (error) {
        if (error.response) {
          if (error.response.status == 500 || error.response.status == 400) {
            this.ErrorPictureUpload = true
            this.loadingPicUpload = false
          }
        }
        return
      }
    }
  }
}
</script>

<style></style>
