<template>
  <!-- Fenster welches alle Bilder zu einer Adresse anzeigt -->
  <v-dialog v-model="opened" persistent max-width="800">
    <v-card :loading="loadingGeneralData">
      <template slot="progress">
        <v-overlay
          absolute
          class="d-flex flex-column text-center"
          opacity="0.75"
        >
          <v-progress-circular
            size="100"
            indeterminate
            color="white"
            width="6"
          />
          <!-- <div class="white--text text-subtitle-1 mt-3">
            Termindetails werden geladen
          </div> -->
        </v-overlay>
      </template>

      <v-toolbar color="primary" dark flat>
        <v-icon @click="closeDialog()">mdi-close</v-icon>
        <v-toolbar-title class="flex text-center">
          <span
            v-if="address.firstname !== undefined && address.lastname !== null"
          >
            Bilderupload für
            {{ address.firstname + ' ' + address.lastname }}</span
          >
          <span v-else> Bilderupload für {{ address.name }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters class="mt-2">
          <span class="font-weight-bold" style="min-width: 60px;"
            >Adresse:</span
          >
          &nbsp;
          <template v-if="address.address !== undefined">
            <span
              v-if="
                address.address.street !== undefined &&
                  address.address.street !== null
              "
            >
              {{ address.address.street }}, &nbsp;</span
            >
            <span
              v-if="
                address.address.zip !== undefined &&
                  address.address.zip !== null
              "
            >
              {{ address.address.zip }}, &nbsp;</span
            >
            <span
              v-if="
                address.address.city !== undefined &&
                  address.address.city !== null
              "
            >
              {{ address.address.city }}</span
            >
          </template>
          <template v-else>
            <span
              v-if="address.street !== undefined && address.street !== null"
            >
              {{ address.street }}, &nbsp;</span
            >
            <span v-if="address.plz !== undefined && address.plz !== null">
              {{ address.plz }}, &nbsp;</span
            >
            <span v-if="address.city !== undefined && address.city !== null">
              {{ address.city }}</span
            >
          </template>
        </v-row>
        <v-row no-gutters>
          <span class="font-weight-bold" style="min-width: 60px;">SVNR:</span>
          <span v-if="address.patient !== undefined && address.patient !== null"
            >&nbsp;{{ address.patient.svnr }}</span
          >
          <span
            v-if="address.patient == undefined || address.patient == null"
            >{{ '-' }}</span
          >
        </v-row>
        <v-divider class="mt-3" />

        <v-tabs v-model="tab" align-with-title scrollable style="width: 100%">
          <v-tabs-slider color=""></v-tabs-slider>
          <v-tab>{{ picTypes[0] }}</v-tab>
          <v-tab>{{ picTypes[1] }}</v-tab>
          <v-tab>{{ picTypes[2] }}</v-tab>
          <v-tab>{{ picTypes[3] }}</v-tab>
          <v-tab>{{ picTypes[4] }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <PictureTable :title="picTypes[0]" :items="images[0]" />
          <PictureTable :title="picTypes[1]" :items="images[1]" />
          <PictureTable :title="picTypes[2]" :items="images[2]" />
          <PictureTable :title="picTypes[3]" :items="images[3]" />
          <PictureTable :title="picTypes[4]" :items="images[4]" />
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showPictureUploadDialog = true">
          <v-icon>
            mdi-upload
          </v-icon>
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>

    <AddressPicturesUploadDialog
      v-if="showPictureUploadDialog"
      :address="address"
      :picTypes="picTypes"
      :appointmentKey="appointmentKey"
      @close-dialog="closeAddressPicturesUploadDialog()"
      @picture-uploaded="loadAddressPictures(address)"
    ></AddressPicturesUploadDialog>
  </v-dialog>
</template>

<script>
import FileHandler from '@/services/utils/fileHandlingService.js'
import AddressPicturesUploadDialog from '@/components/crm/addresses/AddressPicturesUploadDialog.vue'
import { mapState } from 'vuex'
import uploadService from '@/services/crm/uploadService.js'
import PictureTable from '@/components/crm/addresses/pictureUpload/PictureTable.vue'

export default {
  components: {
    AddressPicturesUploadDialog,
    PictureTable
  },
  props: {
    address: Object,
    appointmentKey: Object
  },
  computed: {
    ...mapState({
      addressPictures: state => state.calendarInformation.addressPictures
    })
  },
  data: () => ({
    search: '',
    opened: true,

    showPictureUploadDialog: false,
    tab: null,

    loadingGeneralData: true,
    picTypes: ['Adresse', 'Datenblätter', 'Trittspuren', 'VOS', 'Dokumente'],
    images: []
  }),
  methods: {
    loadPicTypes() {
      uploadService
        .getPictureTypes()
        .then(picTypes => {
          console.log('Pictypes')
          console.log(picTypes)
          this.picTypes = picTypes
        })
        .finally(() => (this.loadingGeneralData = false))
    },

    async loadAddressPictures(address) {
      console.log(this.$store)

      await this.$store.dispatch('loadPicturesForAddress', {
        firma: 1,
        lfdnr: address.lfdnr ?? address.key.lfdnr
      })

      this.images = FileHandler.getAddressPicturesArray(this.addressPictures)
    },
    closeDialog() {
      this.$emit('close-dialog')
    },
    closeAddressPicturesUploadDialog() {
      this.showPictureUploadDialog = false
    }
  },
  mounted() {
    console.warn(this.address)
    this.loadPicTypes()
    this.loadAddressPictures(this.address)
  }
}
</script>

<style></style>
