<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="700"
      v-model="serialAppointmentSummary"
      scrollable
    >
      <template>
        <v-card>
          <v-card-title class="mx-0 px-0 my-0 py-0">
            <v-toolbar color="primary" dark fixed top flat>
              <v-icon @click="serialAppointmentSummary = false"
                >mdi-close</v-icon
              >
              <v-toolbar-title class="flex text-center">
                Terminserie Zusammenfassung
              </v-toolbar-title></v-toolbar
            >
          </v-card-title>

          <v-card-text class="pt-5 pl-10">
            <v-simple-table>
              <thead>
                <td class="pl-4"><b>Nr.</b></td>
                <td class="pl-4"><b>Von</b></td>
                <td class="pl-4"><b>Bis</b></td>
              </thead>
              <tbody>
                <tr
                  class="text-p pa-2"
                  v-for="(appointment, index) in serialPreviews"
                  :key="appointment.start"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      dateTimeService.getGermanDateFormat(
                        appointment.start,
                        false
                      )
                    }}
                  </td>

                  <td>
                    {{
                      dateTimeService.getGermanDateFormat(
                        appointment.end,
                        false
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              depressed
              color="primary"
              class="white--text"
              @click="createSerialAppointment"
              >Anlegen</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <SerialReservedDataDialog ref="serialResDataDialog" @close="close" />
  </div>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService.js'
import calendarService from '@/services/cal/calendarService.js'
import SerialReservedDataDialog from './SerialReservedDataDialog.vue'

export default {
  components: {
    SerialReservedDataDialog
  },
  data: () => ({
    serialAppointmentSummary: false,
    serialPreviews: [],
    dateTimeService,
    SerialReservedDataDialog
  }),
  methods: {
    open(serialPreviews) {
      this.serialPreviews = serialPreviews
      this.serialAppointmentSummary = true
    },
    close() {
      this.$emit('close')
      this.serialAppointmentSummary = false
    },
    createSerialAppointment() {
      calendarService
        .createSerialAppointment(this.serialPreviews)
        .then(response => {
          console.log(response)
          this.$emit('close')
          this.serialAppointmentSummary = false
          this.$toast.success('Terminserie wurde erstellt')
        })
        .catch(error => {
          if (error.status == 409) {
            this.$refs.serialResDataDialog.open(error.data, 'add')
          }
        })
    }
  }
}
</script>

<style></style>
