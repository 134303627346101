import axiosInstance from 'axios'

const setup = store => {
  axiosInstance.interceptors.request.use(
    config => {
      const token = store.state.auth.auth.bearer
      if (token && config.url != '/v1/login' && config.url != '/v1/refresh') {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    res => {
      return res
    },
    async err => {
      const originalConfig = err.config
      if (originalConfig.url !== '/v1/login' && err.response) {
        // Access Token was expired
        if (
          err.response.status === 403 &&
          !originalConfig._retry &&
          store.state.auth.auth.refreshToken
        ) {
          originalConfig._retry = true

          axiosInstance
            .post('/v1/refresh', {
              refreshToken: store.state.auth.auth.refreshToken
            })
            .then(res => {
              store.dispatch('setAuthData', res.data)

              return axiosInstance(originalConfig)
            })
            .catch(error => {
              store.dispatch('reAuth')
              return Promise.reject(error)
            })
        } else if (originalConfig._retry) {
          store.dispatch('reAuth')
        }
      }

      // Reject again, so that error can be handled again
      return Promise.reject(err)
    }
  )
}

export default setup
