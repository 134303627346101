<template>
  <h2 class="text-center text-h5">{{ failedLogins.lastDay }}</h2>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      failedLogins: []
    }
  },
  methods: {
    loadFailedLogins() {
      axios.get('/v1/dashboard/telemetry/failedLogins').then(res => {
        this.failedLogins = res.data
      })
    }
  },

  mounted() {
    this.loadFailedLogins()
  }
}
</script>

<style></style>
