import axios from 'axios'

export default {
  state: {
    taskPreviews: [],
    taskPreviewsUser: [],
    taskPreviewsUser_open: [],
    taskPreviewsUser_done: [],
    taskPreviews_open: [],
    taskPreviews_done: [],
    taskDetailed: {},
    assignableUsers: [],
    assignableWorkGroups: []
  },
  mutations: {
    SET_TASK_PREVIEWS(state, taskPreviews) {
      state.taskPreviews_done = []
      state.taskPreviews_open = []
      state.taskPreviews = taskPreviews
      taskPreviews.forEach(element => {
        if (element.status.done) state.taskPreviews_done.push(element)
        else state.taskPreviews_open.push(element)
      })
    },
    SET_TASK_PREVIEWS_USER(state, taskPreviews) {
      state.taskPreviewsUser_done = []
      state.taskPreviewsUser_open = []
      state.taskPreviewsUser = taskPreviews
      taskPreviews.forEach(element => {
        if (element.status.done) state.taskPreviewsUser_done.push(element)
        else state.taskPreviewsUser_open.push(element)
      })
    },
    SET_TASK_DETAILED(state, taskDetailed) {
      state.taskDetailed = taskDetailed
    },
    SET_ASSIGNABLE_USERS(state, assignableUser) {
      state.assignableUsers = assignableUser
    },
    SET_ASSIGNABLE_WORK_GROUPS(state, assignableWorkGroups) {
      state.assignableWorkGroups = assignableWorkGroups
    }
  },
  actions: {
    async loadTaskPreviews({ commit }) {
      return axios.get('v1/task/previews').then(data => {
        commit('SET_TASK_PREVIEWS', data.data)
      })
    },
    async loadTaskPreviewsForUser({ commit }) {
      return axios.get('v1/task/user/previews').then(data => {
        commit('SET_TASK_PREVIEWS_USER', data.data)
      })
    },
    async loadTask({ commit }, lfdnr) {
      return axios.get(`v1/task/${lfdnr}`).then(data => {
        commit('SET_TASK_DETAILED', data.data)
      })
    },
    async loadAssignableUsers({ commit }) {
      return axios.get('v1/task/details/assignable-users').then(data => {
        commit('SET_ASSIGNABLE_USERS', data.data)
      })
    },
    async loadAssignableWorkGroups({ commit }) {
      return axios.get('v1/task/details/assignable-work-groups').then(data => {
        commit('SET_ASSIGNABLE_WORK_GROUPS', data.data)
      })
    }
  }
}
