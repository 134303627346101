<template>
  <v-dialog v-model="loading" hide-overlay persistent width="300" lazy>
    <v-card color="primary" dark>
      <v-card-text>
        {{ text }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({ loading: false }),
  methods: {
    open(text) {
      this.loading = true
      this.text = text
    },
    close() {
      this.loading = false
    }
  }
}
</script>

<style></style>
