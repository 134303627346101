<template>
  <div class="centerElement">
    <p class="pt-2 text-h2 font-weight-bold text-center">
      {{ time }}
    </p>
    <p class="text-h5 text-center">{{ date }}</p>
  </div>
</template>
<script>
import { DateTime } from 'luxon'

export default {
  data() {
    return {
      time: '',
      date: '',
      interval: null
    }
  },
  methods: {
    updateTime() {
      var jsDate = new Date()
      this.time = DateTime.fromJSDate(jsDate).toFormat('HH:mm') + ' '
      this.date = DateTime.fromJSDate(jsDate).toFormat('DDDD') + ' '
    }
  },
  mounted() {
    this.updateTime()

    this.interval = setInterval(() => {
      this.updateTime()
    }, 35000)
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>

<style></style>
