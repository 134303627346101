<template>
  <div>
    <YearStatisticComponent />
  </div>
</template>

<script>
import YearStatisticComponent from '@/components/home/YearStatisticComponent.vue'

export default {
  data() {
    return {
      yearStatisticTitle: '',
      isMonth: false,
      wj: false
    }
  },
  methods: {
    showMonthYearStatistic(newTitle) {
      this.isMonth = true
      this.yearStatisticTitle = newTitle
    },
    showYearYearStatistic() {
      this.isMonth = false
      this.$refs.yearStatistic.switchToYear()
    },
    changeWj() {
      this.$refs.yearStatistic.filter.wj = this.wj
      this.$refs.yearStatistic.updateFilter()
    },
    toogleGridEditable() {
      this.gridEditable = !this.gridEditable
    }
  },
  components: {
    YearStatisticComponent
  },
  created() {}
}
</script>

<style></style>
