import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import authService from './../core/authService'

const stompService = {
  socket: null,
  stompClient: null,
  headers: {
    Authorization: 'Bearer ' + authService.getBearerFromLocalStorage()
  },
  connected: false,
  async connect() {
    this.socket = new SockJS('/lba-web/websockets')
    this.stompClient = Stomp.over(this.socket)
    this.stompClient.console = false
    await new Promise((resolve, reject) => {
      this.stompClient.connect(
        this.headers,
        () => {
          this.connected = true
          resolve()
        },
        error => {
          this.connected = false
          reject(error)
        }
      )
    })
  },
  subscribe(topic, callback) {
    return this.stompClient.subscribe(
      topic,
      message => {
        var jsonBody = JSON.parse(message.body)
        callback(jsonBody)
      },
      this.headers
    )
  },
  unsubscribe(subscription) {
    subscription.unsubscribe(this.headers)
  },
  send(destination, transferObject) {
    var transferObjectStringified = JSON.stringify(transferObject)
    this.stompClient.send(destination, transferObjectStringified, this.headers)
  },
  disconnect() {
    this.stompClient.disconnect()
    this.socket = null
    this.connected = false
  }
}

export default stompService
