<template>
  <v-footer
    v-if="!loggedIn"
    app
    inset
    color="primary"
    padless
    class="elevation-0"
  >
    <v-container class="d-flex text-center">
      <v-container class="d-flex align-center justify-center pt-4 white--text">
        <strong>{{ company.name }} {{ company.form }}</strong
        >, &nbsp; {{ company.street }}, {{ company.postcode }}
        {{ company.city }}, {{ company.country }},
        <v-icon class="ml-4 mr-1 white--text">mdi-phone</v-icon>
        {{ company.tel }}
        <v-icon class="ml-4 mr-1 white--text">mdi-email</v-icon>
        {{ company.email }}
        <!-------- LBA-Version -------->
        <!----------------------------->
      </v-container>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  mounted: () => {
    store.dispatch('loadCompanyData')
  },
  computed: {
    ...mapGetters(['company', 'loggedIn']),
    VERSION() {
      return `Version: ${process.env.VUE_APP_VERSION}`
    }
  }
}
</script>

<style></style>
