<template>
  <!--TODO: Copied, change required-->
  <v-autocomplete
    v-model="document.customerId"
    :items="searchOutputs"
    item-value="id"
    item-text="name"
    no-filter
    :search-input.sync="searchInputSync"
    :loading="searchOutputsearchLoading"
    loading-text="Globale Suche läuft"
    no-data-text="Keine Einträge gefunden"
    label="LBA durchsuchen (mind. 3 Zeichen)"
    prepend-inner-icon="mdi-magnify"
    outlined
  >
    <template v-slot:item="{ item }">
      <v-list-item @click="openSearchItem(item)">
        <v-avatar size="36" class="primary" v-if="item.type == 'ADDRESS'">
          <v-icon color="white">mdi-account-outline</v-icon>
        </v-avatar>
        <v-avatar size="36" class="primary" v-if="item.type == 'ARTICLE'">
          <v-icon color="white">mdi-archive-outline</v-icon>
        </v-avatar>
        <v-avatar
          size="36"
          label
          class="primary"
          v-if="item.type == 'DOCUMENT'"
        >
          <v-icon color="white">mdi-file-document-outline</v-icon>
        </v-avatar>
        <v-list-item-content class="ml-4">
          <v-list-item-title>
            <span v-if="item.type == 'DOCUMENT'">
              {{
                item.object.shortType +
                  ' ' +
                  item.name +
                  ' / ' +
                  dateTimeService.getShortGermanDate(item.object.date) +
                  ' (' +
                  item.object.state +
                  ')'
              }}
              {{ displayItem(item) }}
            </span>

            <span v-else-if="item.type == 'ADDRESS'">
              {{
                item.name +
                  ', ' +
                  item.object.kdStrasse +
                  ', ' +
                  item.object.kdPlz +
                  ' ' +
                  item.object.kdOrt
              }}
            </span>

            <span v-else-if="item.type == 'ARTICLE'">
              {{ item.name + ' (' + item.object.art_nr + ')' }}
              {{ displayItem(item) }}
            </span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-arrow-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import dateTimeService from '@/services/cal/dateTimeService'

export default {
  data: () => ({
    customerFullTextSearchUrl: '/v1/dashboard/search',
    searchInputSync: '',
    searchOutputsearchLoading: false,
    searchOutputs: [],
    dateTimeService
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    })
  },
  watch: {
    searchInputSync(newValue) {
      if (newValue === '') {
        this.searchOutputs = []
      }
      this.searchTrigger()
    }
  },
  methods: {
    initiateSearchUI() {
      this.searchOutputsearchLoading = true
      this.searchOutputs = []
    },
    searchFullText(query) {
      if (query == undefined) return

      this.initiateSearchUI()

      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel('Operation canceled due to new request.')
      }

      //Save the cancel token for the current request
      this.cancelToken = axios.CancelToken.source()

      axios
        .get(this.customerFullTextSearchUrl, {
          params: { q: query },
          cancelToken: this.cancelToken.token
        })
        .then(response => {
          this.searchOutputs = response.data
          this.searchSuccessfull = true
          this.searchOutputsearchLoading = false
        })
        .catch(err => {
          console.log('error searching address: ' + err)
        })
    },
    searchTrigger() {
      if (
        this.searchInputSync != null &&
        this.searchInputSync != '' &&
        String(this.searchInputSync).length > 2
      ) {
        this.searchStartedOnce = true
        this.searchFullText(this.searchInputSync)
      }
    },
    openSearchItem(item) {
      switch (item.type) {
        case 'ADDRESS':
          this.$router.push({ name: 'crmAddress', params: { lfdnr: item.id } })

          break
        case 'DOCUMENT':
          this.$router.push({
            name: 'crmAddressDocumentDetailed',
            params: {
              lfdnr: item.object.addressLfdnr,
              wf_cid: item.object.wf_cid
            }
          })
          break
        case 'ARTICLE':
          this.$router.push({
            name: 'Artikeldetails',
            params: { articleNumber: item.id }
          })
          break
        default:
          alert('Dieses Feature muss noch implementiert werden.')
      }
    },

    displayItem(item) {
      console.log(item)
    }
  }
}
</script>

<style></style>
