<template>
  <v-dialog v-model="AddressSearch" scrollable max-width="880px">
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-icon @click="AddressSearch = false">mdi-close</v-icon>
          <!--Überschrift-->
          <v-toolbar-title class="flex text-center">Adressen</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mx-0 px-0 my-0 py-0">
        <v-simple-table>
          <tbody>
            <v-data-table
              ref="vuetable"
              :headers="header"
              :items="addresses"
              :items-per-page="10"
              class="elevation-1"
              ><template v-slot:no-data> Keine Daten gefunden. </template
              ><template v-slot:item.actions="{ item }">
                <v-btn
                  fab
                  small
                  text
                  elevation="0"
                  outlined
                  class="mr-2 col-xl-5"
                  @click="addAddress(item)"
                >
                  <v-icon small> mdi-plus </v-icon>
                </v-btn>
              </template>
              ></v-data-table
            >
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="AddressSearch = false"
          >Fertig</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    addresses: [],
    AddressSearch: false,
    header: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'SVNR', value: 'svnr', align: 'left' },
      { text: 'Straße', value: 'street', align: 'left' },
      { text: 'PLZ', value: 'plz', align: 'left' },
      {
        text: 'Hinzufügen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ]
  }),
  methods: {
    open(addresses) {
      this.addresses = addresses
      this.AddressSearch = true
    },
    addAddress(item) {
      this.$emit('addAddress', item)
      this.addresses.splice(this.addresses.indexOf(item), 1)
    }
  }
}
</script>

<style></style>
