<template>
  <div>
    <apexchart
      type="bar"
      :options="options"
      :series="series"
      height="100%"
      v-show="showYear"
    ></apexchart>
    <apexchart
      type="bar"
      :options="optionsMonth"
      :series="seriesMonth"
      :height="350"
      v-show="showMonth"
    ></apexchart>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  props: {},
  data: function() {
    return {
      series: [],
      seriesMonth: [],
      showYear: true,
      selectedYear: '',
      filter: {
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: '',
        db: '',
        locations: [],
        states: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: []
      }
    }
  },
  computed: {
    options: function() {
      return {
        dataLabels: {
          enabled: false
        },
        title: {},
        xaxis: {
          type: 'category'
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              })

              return form.format(value)
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          },
          events: {
            dataPointSelection: (e, chart, config) => {
              var year =
                config.w.globals.seriesX[config.seriesIndex][
                  config.dataPointIndex
                ]
              if (year !== undefined) {
                this.selectedYear = year
                axios
                  .get('v1/statistics/revenue/year/month', {
                    params: {
                      year: year
                    }
                  })
                  .then(data => {
                    this.switchToMonth(data)
                  })
              }
            }
          }
        }
      }
    },
    optionsMonth: function() {
      return {
        dataLabels: {
          enabled: false
        },
        noData: {
          text: 'Daten werden geladen...',
          align: 'center',
          verticalAlign: 'middle'
        },
        title: {},
        xaxis: {
          labels: {
            formatter: value => {
              switch (value) {
                case '1':
                  return 'Jan.'
                case '2':
                  return 'Feb.'
                case '3':
                  return 'März'
                case '4':
                  return 'Apr.'
                case '5':
                  return 'Mai'
                case '6':
                  return 'Jun.'
                case '7':
                  return 'Jul.'
                case '8':
                  return 'Aug.'
                case '9':
                  return 'Sept.'
                case '10':
                  return 'Okt.'
                case '11':
                  return 'Nov.'
                case '12':
                  return 'Dez.'
                default:
                  return value
              }
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              })

              return form.format(value)
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        }
      }
    }
  },

  mounted() {
    this.setDefaultFilter()
  },

  methods: {
    setDefaultFilter() {
      var temp = new Date()
      var temptemp = temp.getFullYear() - 3
      var from = temptemp.toString()
      this.filter.startYear = from
      axios
        .post('v1/statistics/revenue/filter/comparison', this.filter)
        .then(() => {
          this.loadData()
        })
    },
    updateFilter() {
      axios
        .post('v1/statistics/revenue/filter/comparison', this.filter)
        .then(() => {
          this.loadData()
        })
    },
    loadData() {
      axios.get('v1/statistics/revenue/year').then(data => {
        var types = []
        var seriesWrapper = []
        data.data.forEach(element => {
          for (var prop in element.y) {
            if (!types.includes(prop)) types.push(prop)
          }
        })
        types.forEach(type => {
          var series = { name: '', data: [] }
          series.name = type
          var dataSeries = []
          data.data.forEach(element => {
            dataSeries.push({ x: element.x, y: element.y[type] })
          })
          series.data = dataSeries
          console.warn(type)
          console.warn(series)
          seriesWrapper.push(series)
        })
        this.series = seriesWrapper
      })
    },
    switchToMonth(data) {
      console.warn(data)
      this.showYear = false
      var types = []
      var seriesWrapper = []
      data.data.forEach(element => {
        for (var prop in element.y) {
          if (!types.includes(prop)) types.push(prop)
        }
      })
      types.forEach(type => {
        var series = { name: '', data: [] }
        series.name = type
        var dataSeries = []
        data.data.forEach(element => {
          dataSeries.push({ x: element.x, y: element.y[type] })
        })
        series.data = dataSeries
        console.warn(type)
        console.warn(series)
        seriesWrapper.push(series)
      })
      this.seriesMonth = seriesWrapper
      this.showMonth = true
      this.$emit('showMonth', 'Monatsvergleich Umsatz ' + this.selectedYear)
    },
    switchToYear() {
      this.showMonth = false
      this.loadData()
      console.warn(this.options)
      this.showYear = true
    }
  }
}
</script>

<style></style>
