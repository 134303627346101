<template>
  <v-dialog persistent v-model="reservedDataDialog" max-width="650px">
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="warning"
          class="mx-0 px-0 my-0 py-0"
          fixed
          top
          dark
          flat
        >
          <v-toolbar-title class="flex text-center"
            >Terminkonflikt</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mx-0 px-0 my-3 py-0" justify-center>
        <template>
          <div align="center">
            <span class="text-h4">{{ reservedData.appointment.title }}</span>
          </div>

          <v-container fluid>
            <v-row>
              <v-col cols="5.5">
                <v-card elevation="0" style="border: 1px solid #757575">
                  <v-card-text
                    ><div>
                      <p
                        v-if="reservedData.reservedAddresses.length !== 0"
                        class="font-weight-bold"
                      >
                        <v-icon dense class="mt-n1 ml-n1">
                          mdi-account
                        </v-icon>
                        Adressen

                        <span
                          class="text-decoration-line-through red--text class font-weight-regular"
                          v-for="a in reservedData.reservedAddresses"
                          :key="a.key.lfdnr"
                        >
                          <br />
                          {{ a.name }}
                        </span>
                      </p>
                      <p
                        v-if="reservedData.reservedResources.length !== 0"
                        class="font-weight-bold"
                      >
                        <v-icon dense class="mt-n1 ml-n1">
                          mdi-account-tie
                        </v-icon>
                        Ressourcen
                      </p>

                      <span
                        v-for="r in reservedData.reservedResources"
                        class="text-decoration-line-through red--text class font-weight-regular"
                        :key="r.key.lfdnr"
                      >
                        <br />
                        {{ r.name }}
                      </span>
                      <p
                        v-if="reservedData.reservedObjects.length !== 0"
                        class="font-weight-bold"
                      >
                        <v-icon dense class="mt-n1 ml-n1">
                          mdi-package-variant-closed
                        </v-icon>
                        Objekte
                        <span
                          v-for="o in reservedData.reservedObjects"
                          class="text-decoration-line-through red--text class font-weight-regular"
                          :key="o.key.lfdnr"
                        >
                          <br />
                          {{ o.description }}
                        </span>
                      </p>
                      <div class="d-flex justify-center">
                        <v-btn
                          outlined
                          class="orange--text d-flex justify-center"
                          @click="perform('CREATE_WITHOUT')"
                          ><v-icon class="mr-2 ml-n2">
                            mdi-close
                          </v-icon>
                          Kollision entfernen
                        </v-btn>
                      </div>
                    </div></v-card-text
                  >
                </v-card>
              </v-col>
              <v-col cols="5.5">
                <v-card elevation="0" style="border: 1px solid #757575">
                  <v-card-text>
                    <div>
                      <p
                        v-if="reservedData.reservedAddresses.length !== 0"
                        class="font-weight-bold"
                      >
                        <v-icon dense class="mt-n1 ml-n1">
                          mdi-account
                        </v-icon>
                        Adressen

                        <span
                          v-for="a in reservedData.reservedAddresses"
                          class="font-weight-regular"
                          :key="a.key.lfdnr"
                        >
                          <br />
                          {{ a.name }}
                        </span>
                      </p>
                      <p
                        v-if="reservedData.reservedResources.length !== 0"
                        class="font-weight-bold"
                      >
                        <v-icon dense class="mt-n1 ml-n1">
                          mdi-account-tie
                        </v-icon>
                        Ressourcen
                      </p>
                      <span
                        v-for="r in reservedData.reservedResources"
                        class="font-weight-regular"
                        :key="r.key.lfdnr"
                      >
                        <br />
                        {{ r.name }}
                      </span>
                      <p
                        v-if="reservedData.reservedObjects.length !== 0"
                        class="font-weight-bold"
                      >
                        <v-icon dense class="mt-n1 ml-n1">
                          mdi-package-variant-closed
                        </v-icon>
                        Objekte
                        <span
                          v-for="o in reservedData.reservedObjects"
                          class="font-weight-regular"
                          :key="o.key.lfdnr"
                        >
                          <br />
                          {{ o.description }}
                        </span>
                      </p>

                      <div class="d-flex justify-center">
                        <v-btn
                          outlined
                          class="orange--text"
                          @click="perform('CREATE')"
                          ><v-icon class="mr-2 ml-n2">
                            mdi-check
                          </v-icon>
                          Kollision Übernehmen
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card-text>
      <v-card-actions class="pb-4 mt-n4">
        <v-btn
          outlined
          class="flex justify-content-center orange--text"
          @click="closeDialog()"
          ><v-icon class="mr-2 ml-n2">
            mdi-pencil
          </v-icon>
          Termin bearbeiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    reservedDataDialog: false,
    reservedData: null,
    type: '',
    text: ''
  }),
  methods: {
    closeDialog() {
      this.reservedDataDialog = false // Set reservedDataDialog to false to close the dialog
      this.$emit('close') // Emit 'close' event if needed
    },
    open(reservedData, type) {
      this.reservedData = reservedData
      this.reservedDataDialog = true
      this.type = type
      if (type == 'add') this.text = 'Erstellen'
      else if (type == 'edit') this.text = 'Bearbeiten'
    },
    perform(option) {
      if (option === 'DONT_CREATE') {
        this.reservedDataDialog = false
        this.$emit('closeParent')
      } else {
        if (this.type == 'add')
          this.$emit('addWithOption', this.reservedData.appointment, option)
        if (this.type == 'edit') {
          console.log('Reserved')
          this.$emit('editWithOption', this.reservedData.appointment, option)
        }

        this.reservedDataDialog = false
      }
    }
  }
}
</script>

<style></style>
