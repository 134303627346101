<template>
  <v-list>
    <v-list-item
      v-for="doc in documents"
      :key="doc.wf_cid"
      :value="doc.wf_cid"
      link
      :to="{
        name: 'crmAddressDocumentDetailed',
        params: { lfdnr: doc.addressLfdnr, wf_cid: doc.wf_cid }
      }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>
          mdi-file-document
        </v-icon>
      </v-list-item-icon>
      <v-divider vertical class="mr-4 my-2"></v-divider>

      <v-list-item-content>
        <v-list-item-title class="d-flex justify-space-between">
          <span>{{ doc.shortType }} {{ doc.nr }} </span>
          <span> {{ doc.state }}</span>
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex justify-space-between"
          ><span>{{ doc.addressKdSortname }}</span>
          <span>
            {{ dateTimeService.getGermanDateFormatWithoutTime(doc.date) }}
          </span></v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService'
import axios from 'axios'

export default {
  data: function() {
    return {
      documents: [],
      dateTimeService
    }
  },
  methods: {
    loadDocumentsLast() {
      axios.get('/v1/dashboard/documents/last').then(res => {
        this.documents = res.data
      })
    }
  },

  mounted() {
    this.loadDocumentsLast()
  }
}
</script>

<style></style>
