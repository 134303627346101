import axios from 'axios'

export default {
  state: {
    today: -1
  },
  mutations: {
    SET_CUSTOMERS_TODAY(state, newState) {
      state.today = newState
    }
  },
  actions: {
    loadCustomersToday({ commit }) {
      return axios.get('/v1/dashboard/customers/today').then(response => {
        commit('SET_CUSTOMERS_TODAY', response.data)
      })
    }
  },
  getters: {
    customersToday(state) {
      return state.today
    }
  }
}
