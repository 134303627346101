<template>
  <h2 class="text-center text-h5">{{ customersToday }}</h2>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['customersToday'])
  },
  mounted() {
    this.$store.dispatch('loadCustomersToday')
  }
}
</script>

<style></style>
