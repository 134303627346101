<template>
  <v-container>
    <div class="my-4 pa-4 mx-2 d-flex justify-space-between ">
      <h2 class="primary--text text-h4 font-weight-bold">
        {{ greet }} {{ user.username }}!
      </h2>

      <img :src="company.logo" style="max-height: 40px" />
    </div>
    <SearchComponent class="px-6"></SearchComponent>
    <div class="d-flex justify-start" v-if="FEATURE_DASHBOARD_EDITABLE">
      <span
        class="ml-5 pa-2 pt-1 grey--text text--darken-2 rounded-lg font-weight-bold"
        >LAYOUT:</span
      >

      <v-btn
        @click="toogleGridEditable"
        icon
        outlined
        class="mr-2"
        :class="{ success: gridEditable, 'white--text': gridEditable }"
        v-show="toogleGridEditable"
      >
        <v-icon>{{ gridEditButton }}</v-icon>
      </v-btn>
      <v-btn icon outlined @click="dialog = true">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-dialog v-model="dialog" scrollable width="auto">
        <v-card>
          <v-card-title>Verfügbare Widgets</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="min-width: 360px;">
            <v-list-item dense v-for="item in layout" :key="item.i">
              <v-checkbox
                v-model="widgetsEnabled"
                :label="widgetLabels[item.i]"
                :value="item.i"
              ></v-checkbox>
            </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="success" variant="text" @click="dialog = false">
              speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div>
      <grid-layout
        class="mt-n4"
        :layout.sync="layout"
        :responsive-layouts="responsiveLayouts"
        :col-num="12"
        :row-height="30"
        :is-draggable="gridEditable"
        :is-resizable="gridEditable"
        :responsive="layoutResponsive"
        :vertical-compact="true"
        :use-css-transforms="true"
        @breakpoint-changed="breakpointChangedEvent"
        :margin="[25, 25]"
      >
        <grid-item
          v-for="item in layout"
          :key="item.i"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
        >
          <smart-widget
            :title="getWidgetTitle(item.i)"
            style="border-left: 5px solid var(--v-primary-base); border-radius: 0px;"
          >
            <ClockComponent v-if="item.i == 'CLOCK'" />
            <CustomerTodayComponent v-if="item.i == 'CUSTOMERS_TODAY'" />
            <TelemetryFailedLoginsComponent
              v-if="item.i == 'TELEMETRY_FAILED_LOGINS'"
            />
            <AgendaComponent v-if="item.i == 'APPOINTMENTS'" />
            <DocumentsLast v-if="item.i == 'DOCUMENTS_LAST'" />
            <InteractionsLast v-if="item.i == 'INTERACTIONS_LAST'" />
            <TasksComponent v-if="item.i == 'TASKS'" />

            <MonthStatisticComponent v-if="item.i == 'STATISTIC_MONTH'" />
            <TurnoverStatisticComponent v-if="item.i == 'STATISTIC_TURNOVER'" />
            <OpenPositions v-if="item.i == 'OPEN_POSITIONS'" />

            <!-- TODO: Abklärung mit RAPKA -->
            <YearStatisticComponentWrapper v-if="item.i == 'STATISTIC_YEAR'" />
          </smart-widget>
        </grid-item>
      </grid-layout>
      <!--
        <smart-widget
          slot="STATISTIC_YEAR"
          :title="
            isMonth === true ? yearStatisticTitle : 'Jahresvergleich Umsatz'
          "
          shadow="hover"
        >
          <template slot="toolbar">
            <v-btn
              class="mr-2"
              @click="showYearYearStatistic"
              v-if="isMonth"
              elevation="0"
              ><v-icon class="mr-1">mdi-arrow-left</v-icon> Zurück</v-btn
            >
            <v-checkbox
              class="mr-4"
              v-else
              v-model="wj"
              label="Wirtschaftjahr"
              @change="changeWj"
            ></v-checkbox>
          </template>
          <YearStatisticComponent
            ref="yearStatistic"
            @showMonth="showMonthYearStatistic"
          ></YearStatisticComponent>
        </smart-widget>-->
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import { GridLayout, GridItem } from 'vue-grid-layout'

import SearchComponent from '@/components/home/SearchComponent.vue'
import ClockComponent from '@/components/home/ClockComponent.vue'
import AgendaComponent from '@/components/home/AgendaComponent.vue'
import TasksComponent from '@/components/home/TasksComponent.vue'
import CustomerTodayComponent from '@/components/home/CustomerTodayComponent.vue'
import TelemetryFailedLoginsComponent from '@/components/home/TelemetryFailedLoginsComponent.vue'
import DocumentsLast from '@/components/home/DocumentsLast.vue'
import YearStatisticComponentWrapper from '@/components/home/YearStatisticComponentWrapper.vue'
import MonthStatisticComponent from '@/components/home/MonthStatisticComponent.vue'
import { mapGetters } from 'vuex'
import store from '@/store'
import InteractionsLast from '@/components/home/InteractionsLast.vue'
import OpenPositions from '@/components/home/OpenPositions.vue'
import TurnoverStatisticComponent from '@/components/home/TurnoverStatisticComponent.vue'

export default {
  name: 'Home',
  data() {
    return {
      gridEditable: false,
      widgetsEnabled: [],
      widgetLabels: {
        CLOCK: 'Uhrzeit',
        APPOINTMENTS: 'Agenda',
        CUSTOMERS_TODAY: 'Kunden heute',
        DOCUMENTS_LAST: 'Zuletzt bearbeitete Dokumente',
        INTERACTIONS_LAST: 'Neuste Interaktionen',
        OPEN_POSITIONS: 'Offene Posten',
        STATISTIC_MONTH: 'Monatsvergleich Umsatz',
        STATISTIC_TURNOVER: 'Aktuelle Umsätze',
        STATISTIC_YEAR: 'Jahresumsatz',
        TASKS: 'Aufgaben',
        TELEMETRY_FAILED_LOGINS: 'Fehlgeschlagene Logins 24h'
      },
      layoutResponsive: true,
      layout: [],
      //Breakpoints defined for responsive layout, the parameter represents the width of different devices:lg(large), md(medium), sm(small), xs(extra small).
      responsiveLayouts: {},
      defaultResponsiveLayout: {
        xxs: [
          { x: 0, y: 6, w: 3, h: 6, i: 'TASKS', moved: false },
          { x: 0, y: 12, w: 4, h: 14, i: 'APPOINTMENTS', moved: false },
          { x: 0, y: 0, w: 2, h: 6, i: 'CLOCK', moved: false }
        ],
        md: [
          { x: 0, y: 5, w: 4, h: 9, i: 'TASKS', moved: false },
          { x: 4, y: 0, w: 6, h: 14, i: 'APPOINTMENTS', moved: false },
          { x: 0, y: 0, w: 4, h: 5, i: 'CLOCK', moved: false }
        ],
        sm: [
          { x: 0, y: 6, w: 2, h: 8, i: 'TASKS', moved: false },
          { x: 2, y: 0, w: 4, h: 14, i: 'APPOINTMENTS', moved: false },
          { x: 0, y: 0, w: 2, h: 6, i: 'CLOCK', moved: false }
        ],
        lg: [
          { x: 0, y: 5, w: 5, h: 10, i: 'TASKS', moved: false },
          { x: 5, y: 0, w: 7, h: 15, i: 'APPOINTMENTS', moved: false },
          { x: 0, y: 0, w: 5, h: 5, i: 'CLOCK', moved: false }
        ],
        xs: [
          { x: 0, y: 5, w: 4, h: 6, i: 'TASKS', moved: false },
          { x: 0, y: 11, w: 4, h: 11, i: 'APPOINTMENTS', moved: false },
          { x: 0, y: 0, w: 4, h: 5, i: 'CLOCK', moved: false }
        ]
      },
      currentBreakPoint: '',
      greet: ['Guten Morgen', 'Guten Morgen', 'Guten Tag', 'Guten Abend'][
        parseInt((new Date().getHours() / 24) * 4)
      ],
      yearStatisticTitle: '',
      isMonth: false,
      wj: false,
      dialog: false
    }
  },
  methods: {
    breakpointChangedEvent: function(newBreakpoint, newLayout) {
      this.responsiveLayouts[newBreakpoint] = newLayout
      this.currentBreakPoint = newBreakpoint

      //this.setMyWidgets()
    },

    getWidgetTitle(id) {
      return this.widgetLabels[id] != undefined ? this.widgetLabels[id] : id
    },

    showMonthYearStatistic(newTitle) {
      this.isMonth = true
      this.yearStatisticTitle = newTitle
    },
    showYearYearStatistic() {
      this.isMonth = false
      this.$refs.yearStatistic.switchToYear()
    },
    changeWj() {
      this.$refs.yearStatistic.filter.wj = this.wj
      this.$refs.yearStatistic.updateFilter()
    },
    toogleGridEditable() {
      //save grid
      if (this.gridEditable) this.setMyWidgets()

      this.gridEditable = !this.gridEditable
    },
    addWidgets() {
      alert('todo: add widgets')
    },
    setMyWidgets() {
      if (JSON.stringify(this.responsiveLayouts) === '{}') return
      if (this.layout.length === 0) return

      this.responsiveLayouts[this.currentBreakPoint] = this.layout

      return axios.put('/v1/dashboard', this.responsiveLayouts)
    },
    getMyWidgets() {
      return axios.get('/v1/dashboard').then(res => {
        console.debug(res.data)
        if (Object.keys(res.data).length === 0) {
          this.responsiveLayouts = this.defaultResponsiveLayout
        } else {
          this.responsiveLayouts = res.data
        }

        this.layout = this.responsiveLayouts['sm']
      })
    },
    getAllAvailableWidgets() {
      axios.get('/v1/dashboard/widgets').then(res => {
        console.debug(res.data)
      })
    }
  },
  components: {
    GridLayout,
    GridItem,
    SearchComponent,
    ClockComponent,
    AgendaComponent,
    CustomerTodayComponent,
    TelemetryFailedLoginsComponent,
    DocumentsLast,
    TasksComponent,
    YearStatisticComponentWrapper,
    MonthStatisticComponent,
    InteractionsLast,
    TurnoverStatisticComponent,
    OpenPositions
  },
  computed: {
    FEATURE_DASHBOARD_EDITABLE() {
      return process.env.VUE_APP_FEATURE_DASHBOARD_EDITABLE === 'true'
    },
    ...mapGetters(['user', 'company', 'activeWidgets']),
    gridEditButton() {
      //return this.gridEditable ? 'Layout speichern' : 'Layout bearbeiten'
      return this.gridEditable ? 'mdi-content-save' : 'mdi-pencil'
    }
  },
  created() {
    store.dispatch('loadVisibleTabs')
    store.dispatch('loadUserWidgets')
    this.getMyWidgets()
    this.getAllAvailableWidgets()
  }
}
</script>
<style>
.widget-header {
  color: var(--v-primary-base);
  font-weight: bold;
  height: 42px !important;
  line-height: 42px !important;
}
.widget-header__title {
  font-weight: bold !important;
  font-size: 1.4rem !important;
}

.widgetNoDataPlaceholder {
  height: 77%;
}

.widget-body__content {
  overflow: auto;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
