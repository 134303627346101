<template>
  <div>
    <div v-if="tasks.length > 0">
      <!-- TODO: Open task on click -->

      <v-data-table
        :headers="headers"
        :items="tasks"
        :items-per-page="20"
        :sort-by="['dueDate', 'lfdnr']"
        hide-default-footer
        class="datatable-row-pointer"
      >
        <template v-slot:item.lfdnr="{ item }">
          <v-chip :color="getRowColor(item, 'primary')">
            {{ item.lfdnr }}
          </v-chip>
        </template>

        <template v-slot:item.dueDate="{ item }">
          <div>
            <v-icon :color="getRowColor(item, 'primary')" class="pr-1 mt-n1">
              mdi-clock
            </v-icon>
            {{ styleDate(item.dueDate) }}
          </div>
        </template>
      </v-data-table>
    </div>

    <div v-else>
      <div>
        <img
          src="@/assets/svg/undraw_completing_re_i7ap.svg"
          alt="Task-finished"
          class="pa-3 centerElement widgetNoDataPlaceholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import dateTimeService from '@/services/cal/dateTimeService.js'

export default {
  data: function() {
    return {
      headers: [
        {
          text: 'Nr',
          align: 'start',
          value: 'lfdnr'
        },
        { text: 'Betreff', value: 'title.title' },
        { text: 'Bis', value: 'dueDate' }
      ],
      tasks: []
    }
  },
  methods: {
    loadTasks() {
      axios.get('/v1/dashboard/tasks').then(res => {
        this.tasks = res.data
      })
    },

    taskDueDatePassed(task) {
      return (
        dateTimeService.getMinutesDifference(
          new Date(),
          new Date(task.dueDate)
        ) < 0
      )
    },

    getRowColor(task, defaultColor) {
      let minutesDiff = dateTimeService.getMinutesDifference(
        new Date(),
        new Date(task.dueDate)
      )

      if (minutesDiff < 0) {
        return 'error'
      } else if (minutesDiff < 60) {
        return 'warning'
      } else {
        return defaultColor
      }
    },

    styleDate(date) {
      return dateTimeService.getLocalTimeWithDateAndTime(new Date(date))
    }
  },

  mounted() {
    this.loadTasks()
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
