<template>
  <div>
    <v-dialog v-model="serialReservedDataDialog" scrollable max-width="650px">
      <v-card>
        <v-card-title class="mx-0 px-0 my-0 py-0">
          <v-toolbar
            color="warning"
            class="mx-0 px-0 my-0 py-0"
            fixed
            top
            dark
            flat
          >
            <!--Überschrift-->
            <v-toolbar-title class="flex text-center"
              >Serienterminkonflikt</v-toolbar-title
            >
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mx-0 px-0 my-3 py-0">
          <v-container fluid>
            <v-card
              class="mx-10 my-5"
              elevation="0"
              style="border: 1px solid #757575"
              v-for="conflict in conflicts"
              v-bind:key="conflict.appointment.key"
            >
              <v-card-title>
                {{ conflict.appointment.title }}
              </v-card-title>
              <v-card-text>
                <div v-if="conflict.reservedAddresses.length > 0">
                  <p>Adresskonflikt:</p>
                  <p>{{ conflict.reservedAddresses[0].name }}</p>
                </div>
                <div v-if="conflict.reservedObjects.length > 0">
                  <p>Objektkonflikt</p>
                  <p>{{ conflict.reservedObjects[0].name }}</p>
                </div>
                <div v-if="conflict.reservedResources.length > 0">
                  <p>Ressourcenkonflikt</p>
                  <p>{{ conflict.reservedResources[0].name }}</p>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  outlined
                  class="orange--text d-flex justify-center"
                  @click="perform('CREATE_WITHOUT', conflict)"
                  ><v-icon class="mr-2 ml-n2">
                    mdi-close
                  </v-icon>
                  Kollision entfernen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  class="orange--text"
                  @click="perform('CREATE', conflict)"
                  ><v-icon class="mr-2 ml-n2">
                    mdi-check
                  </v-icon>
                  Kollision Übernehmen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            class="orange--text d-flex justify-center"
            @click="performAll('CREATE_WITHOUT')"
            ><v-icon class="mr-2 ml-n2">
              mdi-close
            </v-icon>
            Alle entfernen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined class="orange--text" @click="performAll('CREATE')"
            ><v-icon class="mr-2 ml-n2">
              mdi-check
            </v-icon>
            Alle übernehmen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import calendarService from '@/services/cal/calendarService.js'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    calendarService
  },
  data: () => ({
    serialReservedDataDialog: false,
    reservedData: null,
    type: '',
    text: '',
    conflicts: []
  }),
  methods: {
    open(conflicts, type) {
      this.conflicts = conflicts
      this.type = type
      this.serialReservedDataDialog = true
      console.log('Opening dialog reserved serial')
      console.warn(conflicts)
    },
    async performAll(action) {
      this.$emit('close')
      for (var conflict of this.conflicts) {
        await this.perform(action, conflict)
      }
    },
    async perform(action, conflict) {
      console.log(conflict)
      let conflictCopy = JSON.parse(JSON.stringify(conflict.appointment))
      conflictCopy.conflictResolution = action

      if (this.type == 'add') {
        await calendarService.addAppointment(conflictCopy).then(() => {
          this.$toast.success('Termin wurde erstellt')
        })
      } else if (this.type == 'edit') {
        await calendarService.updateAppointment(conflictCopy).then(() => {
          this.$toast.success('Termin wurde gespeichert')
        })
      }

      this.conflicts = this.conflicts.filter(item => item !== conflict)
      if (this.conflicts.length == 0) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style></style>
