import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import authService from '@/services/core/authService.js'
import NotificationService from '@/services/notifications/notifications.js'

export default {
  state: {
    auth: {
      bearer: authService.getBearerFromLocalStorage(),
      refreshToken: authService.getRefreshTokenFromLocalStorage()
    }
  },
  mutations: {
    SET_AUTH_DATA(state, authData) {
      Vue.set(state, 'auth', authData)
      localStorage.setItem('auth', JSON.stringify(authData))
    },
    CLEAR_AUTH_DATA() {
      localStorage.removeItem('auth')
      location.reload()
    }
  },
  actions: {
    loadAuthFromLocalStorage({ commit }) {
      var auth = JSON.parse(localStorage.getItem('auth'))

      if (auth != null) {
        commit('SET_AUTH_DATA', auth)
      }
    },
    setAuthData({ commit }, data) {
      commit('SET_AUTH_DATA', data)
    },
    login({ commit }, credentials) {
      return axios.post('/v1/login', credentials).then(response => {
        commit('SET_AUTH_DATA', response.data)
        if (router.currentRoute.query.redirectTo == null) {
          router.currentRoute.query.redirectTo = '/'
        }
        router.push(router.currentRoute.query.redirectTo)

        NotificationService.registerDeviceIdWithCurrentUser()
      })
    },
    reAuth({ commit }) {
      commit('CLEAR_AUTH_DATA')
    },
    async logout({ commit, state }) {
      axios.post('/v1/logout', { refreshToken: state.auth.refreshToken })
      router.push('/login?logout=true')
      commit('CLEAR_AUTH_DATA')
    }
  },
  getters: {
    loggedIn(state) {
      return !!state.auth.bearer
    }
  }
}
