<template>
  <v-dialog v-model="opened" width="600">
    <v-card>
      <v-toolbar color="error" dark flat>
        <v-icon @click="closeDialog">mdi-close</v-icon>
        <v-toolbar-title class="flex text-center">
          Ein Fehler ist aufgetreten
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="pa-4">
        <v-row>
          <v-col cols="12">
            {{ failMessage }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex flex-column align-end" cols="12">
            <v-btn outlined class="red--text" @click="closeDialog"
              ><v-icon class="pr-3">mdi-cancel</v-icon>Schließen</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    failMessage: {
      type: String,
      required: true
    },
    opened: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style></style>
