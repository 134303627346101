<template>
  <v-dialog v-model="serialAppointmentDialog" max-width="400"
    ><v-card>
      <v-toolbar color="primary" dark flat>
        <v-icon @click="serialAppointmentDialog = false">mdi-close</v-icon>
        <v-toolbar-title class="flex text-center"
          >Terminserie erstellen</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="mt-6">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="serialData.repetitionCycle"
              :min="1"
              class="shrink"
              dense
              label="Wiederholen alle"
              type="number"
              :suffix="serialData.typeOfRepetition"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="serialData.typeOfRepetition"
              :items="typesOfRepetition"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="serialData.typeOfRepetition == 'Wochen'">
          <v-col cols="12">
            <v-select
              v-model="serialData.weekdays"
              :items="weekdays"
              dense
              multiple
              hint="Wochentag"
              label="Wiederholen am"
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="serialData.typeOfRepetition == 'Monate'">
          <v-col cols="12">
            <v-select
              v-model="serialData.dayOfMonth"
              :items="repeatTypeMonth"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center" align="center" dense>
          <v-col cols="12">
            <v-radio-group v-model="endType" row label="Ende">
              <v-radio
                v-for="n in 2"
                :key="n"
                :label="endTypes[n - 1]"
                :value="n"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" v-if="endType == 1">
            <v-menu
              ref="datepicker"
              v-model="datepicker"
              :close-on-content-click="false"
              :return-value.sync="serialData.repeatUntil"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-col cols="12" sm="12" class="pa-0">
                  <!--Textfeld welches das Datum anzeigt-->
                  <v-text-field
                    v-model="serialData.repeatUntil"
                    class="shrink"
                    label="Ende am"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </v-col>
              </template>
              <!--Datepicker-->
              <v-date-picker
                v-model="serialData.repeatUntil"
                no-title
                scrollable
                first-day-of-week="1"
                color="primary"
              >
                <v-spacer></v-spacer>
                <!--Button um Datepicker zu schließen-->
                <v-btn text color="primary" @click="datepicker = false"
                  >Cancel</v-btn
                >
                <!--Button welcher Datepicker schließt und das ausgewählte Datum speichert-->
                <v-btn
                  text
                  color="primary"
                  @click="$refs.datepicker.save(serialData.repeatUntil)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="endType == 2">
            <v-text-field
              v-model="serialData.repetitions"
              hide-details
              :min="2"
              class="shrink"
              dense
              label="Ende nach"
              suffix="Terminen"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="white--text"
          @click="calcAppointments"
          elevation="0"
          >Termine berechnen</v-btn
        >
      </v-card-actions>
      <SerialAppointmentSummary ref="summary" @close="close" /> </v-card
  ></v-dialog>
</template>

<script>
import calendarService from '@/services/cal/calendarService.js'
import { mapState } from 'vuex'
import SerialAppointmentSummary from '@/components/calendar_v2/SerialAppointmentSummary.vue'

export default {
  data: () => ({
    serialAppointmentDialog: false,
    appointment: [],
    typesOfRepetition: ['Tage', 'Wochen', 'Monate'],
    endTypes: ['am', 'nach'],
    endType: 1,
    datepicker: false,
    serialData: {},
    weekdays: [
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
      'Sonntag'
    ],
    today: new Date(Date.now()),
    repeatTypeMonth: ['Monatstag', 'Wochentag'],
    calendarService
  }),
  components: {
    SerialAppointmentSummary
  },
  computed: {
    ...mapState({
      serialAppointmentPreviews: state =>
        state.calAppointments.serialAppointmentPreviews
    })
  },
  methods: {
    open(appointment) {
      this.setDefaultValues()
      this.appointment = appointment
      this.serialAppointmentDialog = true
    },
    close() {
      this.$emit('close')
      this.serialAppointmentDialog = false
    },
    setDefaultValues() {
      this.endType = 2
      this.serialData = {
        typeOfRepetition: 'Tage',
        repetitionCycle: 1,
        repetitions: 2,
        weekdays: [],
        repeatUntil: this.today.toISOString().substring(0, 10),
        dayOfMonth: 'Monatstag'
      }
    },
    calcAppointments() {
      console.warn(this.serialData)
      var converted = JSON.parse(JSON.stringify(this.serialData))
      converted.dayOfMonth =
        converted.dayOfMonth === 'Monatstag'
          ? (converted.dayOfMonth = true)
          : (converted.dayOfMonth = false)

      var weekdays = this.weekdays
      converted.weekdays = converted.weekdays.map(function(day) {
        return weekdays.indexOf(day) + 1
      })

      switch (converted.typeOfRepetition) {
        case 'Tage':
          converted.typeOfRepetition = 'DAILY'
          break
        case 'Monate':
          converted.typeOfRepetition = 'MONTHLY'
          break
        case 'Wochen':
          converted.typeOfRepetition = 'WEEKLY'
          break
      }

      switch (this.endType) {
        case 1:
          converted.repetitions = null
          break
        case 2:
          converted.repeatUntil = this.today
          break
      }
      converted.firstAppointment = this.appointment
      console.warn(converted)
      this.$store
        .dispatch('loadSerialAppointmentPreviews', converted)
        .then(() => {
          console.warn(this.serialAppointmentPreviews)
          this.$refs.summary.open(this.serialAppointmentPreviews)
        })
    }
  }
}
</script>

<style></style>
