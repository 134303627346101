<template>
  <apexchart
    type="bar"
    height="98%"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      series: []
    }
  },
  computed: {
    options: function() {
      return {
        dataLabels: {
          enabled: false
        },
        title: {},
        yaxis: {
          labels: {
            formatter: function(value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              })

              return form.format(value)
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        }
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      axios.get('v1/dashboard/revenue/turnover').then(data => {
        var types = []
        var seriesWrapper = []
        data.data.forEach(element => {
          for (var prop in element.y) {
            if (!types.includes(prop)) types.push(prop)
          }
        })
        types.forEach(type => {
          var series = { name: '', data: [] }
          series.name = type
          var dataSeries = []
          data.data.forEach(element => {
            dataSeries.push({ x: element.x, y: element.y[type] })
          })
          series.data = dataSeries
          console.warn(type)
          console.warn(series)
          seriesWrapper.push(series)
        })
        this.series = seriesWrapper
      })
    }
  }
}
</script>

<style></style>
