<template>
  <v-dialog
    v-model="editAppointment"
    persistent
    scrollable
    max-width="700"
    @keydown.esc="editAppointment = false"
  >
    <v-form v-model="formValid" ref="form">
      <v-card>
        <v-card-title class="ma-0 pa-0">
          <v-toolbar
            class="primary--text border-bottom-primary-5"
            top
            flat
            style="background-color: white;"
          >
            <!-- Button zum Schließen des Dialogs -->
            <v-icon
              @click="
                editAppointment = false
                unlockAppointment()
              "
              >mdi-close</v-icon
            >
            <!--Überschrift-->
            <v-toolbar-title class="flex text-center" v-if="type == 'edit'"
              >Termin bearbeiten</v-toolbar-title
            >
            <v-toolbar-title class="flex text-center" v-if="type == 'add'"
              >Termin erstellen</v-toolbar-title
            >
            <v-toolbar-title class="flex text-center" v-if="type == 'show'"
              >Termindetails</v-toolbar-title
            >
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container fluid>
            <v-row>
              <v-col cols="12" lg="12" xl="12">
                <v-text-field
                  outlined
                  label="Betreff"
                  v-model="appointment.title"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-expansion-panels v-model="expansionPanelModel" multiple flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="border-left-primary-3 ">
                  <h4>Datum und Uhrzeit</h4></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <!-- BEGINN / ENDE -->
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        readonly
                        label="Beginn"
                        :value="this.startDateString"
                        @click="openDateTimePicker('start')"
                        ><v-icon>mdi-calendar-clock</v-icon></v-text-field
                      >
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        readonly
                        label="Ende"
                        :value="this.endDateString"
                        @click="openDateTimePicker('end')"
                        ><v-icon>mdi-calendar-clock</v-icon></v-text-field
                      >
                    </v-col>
                  </v-row>
                  <!-- Checkbox Ganztägig -->
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        label="Ganztägig"
                        v-model="appointment.allDay"
                        inset
                        @change="recalculateDateTime('allDay')"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-select
                        suffix="Tage"
                        dense
                        :items="days"
                        :disabled="type === 'show'"
                        v-model="durationSplit.days"
                        @change="recalculateDateTime('duration')"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4" v-if="!appointment.allDay">
                      <v-select
                        suffix="Stunden"
                        dense
                        :items="hours"
                        :disabled="type === 'show'"
                        v-model="durationSplit.hours"
                        @change="recalculateDateTime('duration')"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4" v-if="!appointment.allDay">
                      <v-select
                        suffix="Minuten"
                        dense
                        :items="minutes"
                        :disabled="type === 'show'"
                        v-model="durationSplit.minutes"
                        @change="recalculateDateTime('duration')"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mt-1">
                <v-divider></v-divider>
                <v-expansion-panel-header class="border-left-primary-3 "
                  ><h4>Details</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-3">
                    <v-col cols="12" lg="6" xl="6">
                      <v-select
                        label="Status"
                        :items="states"
                        :disabled="type === 'show'"
                        dense
                        item-text="description"
                        v-model="appointment.state"
                        :item-value="item => item"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6" xl="6">
                      <v-select
                        label="Bereich"
                        :items="scopes"
                        dense
                        :disabled="type === 'show'"
                        item-text="name"
                        v-model="appointment.scope"
                        :item-value="item => item"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col cols="12" lg="6" xl="6">
                      <v-select
                        label="Terminart"
                        :items="types"
                        dense
                        item-text="name"
                        :disabled="type === 'show'"
                        v-model="appointment.type"
                        :item-value="item => item"
                        @change="showingDefDuration = true"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6" xl="6">
                      <v-select
                        label="Lokation"
                        :items="locations"
                        dense
                        :disabled="type === 'show'"
                        item-text="name"
                        v-model="appointment.location"
                        :item-value="item => item"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="showingDefDuration">
                    <v-col cols="3" lg="2" xl="2"></v-col>
                    <v-col cols="2" lg="4" xl="4"
                      ><v-btn
                        small
                        @click="setDefDuration"
                        justify-center
                        class="mt-n10"
                        :disabled="appointment.type.defDuration == 0"
                        >Dauer übernehmen ({{ appointment.type.defDuration }}
                        min)
                      </v-btn></v-col
                    >
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mt-1">
                <v-divider></v-divider>
                <v-expansion-panel-header class="border-left-primary-3"
                  ><h4>Personen und Objekte</h4></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row class="mt-3" dense align="center">
                    <v-col cols="10" lg="10" xl="10">
                      <v-text-field
                        v-model="searchText"
                        label="Adresssuche"
                        outlined
                        hide-details
                        v-on:keyup.enter="searchAddresses"
                      ></v-text-field>
                    </v-col>
                    <!--<v-col cols="6">
                      <div class="align-center">
                        <NameSearchHelpDialog />
                      </div>
                    </v-col>-->
                    <v-col cols="2" lg="2" xl="2">
                      <v-container>
                        <v-btn
                          fab
                          outlined
                          color="primary darken-2"
                          @click="searchAddresses"
                        >
                          <v-icon>mdi-account-search</v-icon>
                        </v-btn>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row v-if="appointment.addresses.length != 0">
                    <v-col cols="12">
                      <v-list lines="one">
                        <v-list-item
                          v-for="item in appointment.addresses"
                          class="pa-0"
                          :key="item.key.lfdnr"
                        >
                          <v-list-item-action>
                            <v-btn fab icon small
                              ><v-icon
                                color="grey darken-2"
                                small
                                @click="showPictures(item)"
                                >mdi-image-multiple</v-icon
                              ></v-btn
                            >

                            <v-btn fab icon small
                              ><v-icon
                                color="red darken-2"
                                small
                                @click="removeAddress(item)"
                                >mdi-account-remove</v-icon
                              ></v-btn
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-text
                              class="d-inline-flex flex-wrap"
                              v-if="currentDisplay !== 'xs'"
                            >
                              SVNR: {{ item.svnr }} <br />
                              {{ item.street }}, {{ item.plz }} {{ item.city }}
                              <br />
                              {{ item.tel }} <br />
                              {{ item.email }}
                            </v-list-item-text>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-3"
                    v-if="appointment.addresses.length != 0"
                    align="center"
                    justify="center"
                  >
                    <v-col cols="12" lg="2" xl="2">
                      <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        rounded
                        elevation="0"
                        @click="openObjectList"
                        :disabled="type === 'show'"
                        ><v-icon>mdi-package-variant-closed</v-icon
                        >Objekte</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="appointment.objects.length != 0">
                    <v-col cols="12" lg="10" xl="10">
                      <v-list lines="one">
                        <v-list-item
                          v-for="item in appointment.objects"
                          :key="item.key.code"
                        >
                          <v-list-item-content class="overflow-visible">
                            <v-list-item-title>{{
                              item.description
                            }}</v-list-item-title>
                          </v-list-item-content>
                          <v-spacer />

                          <v-list-item-icon
                            ><v-icon
                              color="red darken-2"
                              small
                              :disabled="type === 'show'"
                              @click="removeObject(item)"
                              >mdi-package-variant-closed-minus</v-icon
                            ></v-list-item-icon
                          >
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col cols="12" lg="12" xl="12">
                      <v-select
                        label="Ressourcen"
                        :items="resources"
                        dense
                        item-text="name"
                        :disabled="type === 'show'"
                        chips
                        deletable-chips
                        multiple
                        v-model="appointment.resources"
                        :item-value="item => item"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0" v-if="type == 'edit'">
            <v-col cols="12">
              <v-container class="pa-0 d-flex justify-space-around">
                <v-btn
                  outlined
                  color="success"
                  class="white--text"
                  elevation="1"
                  @click="edit(), unlockAppointment()"
                  >Speichern</v-btn
                >
              </v-container>
            </v-col>
          </v-row>

          <v-row class="ma-0 d-flex justify-space-around" v-if="type == 'add'">
            <v-col cols="12" md="6" xl="6" lg="6">
              <v-btn block outlined color="success" @click="add()" elevation="1"
                >Einzeltermin Erstellen</v-btn
              >
            </v-col>
            <v-col cols="12" md="6" xl="6" lg="6">
              <v-btn
                block
                outlined
                color="primary"
                v-if="type == 'add'"
                @click="openSerialAppointmentDialog()"
                elevation="1"
                >Terminserie erstellen</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <AddressSearch
        ref="addressSearch"
        @addAddress="addAddress"
      ></AddressSearch>
      <ObjectList ref="objectList" @addObject="addObject"></ObjectList>
      <Loading ref="loading"></Loading>
      <DateTimePicker ref="startPicker" @save="saveStart" />
      <DateTimePicker ref="endPicker" @save="saveEnd" />
      <AddressPicturesDialog
        v-if="showAddressPicturesDialog"
        @close-dialog="closeAddressPicturesDialog()"
        :address="currentAddress"
        :opened="true"
      />
      <ErrorDialog
        :failMessage="failMessage"
        :opened="errorDialog"
        @closeDialog="errorDialog = false"
      />
      <ReservedDataDialog
        ref="reserved"
        @addWithOption="add"
        @editWithOption="edit"
        @closeParent="close"
      />

      <SerialAppointmentDialog ref="serial" @close="close" />
      <SerialReservedDataDialog
        ref="reservedSerial"
        @close="closeSerialReservedDataDialog"
      />
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import dateTimeService from '@/services/cal/dateTimeService.js'
//import NameSearchHelpDialog from '@/components/crm/addresses/NameSearchHelpDialog.vue'
import AddressSearch from '@/components/calendar_v2/AddressSearch.vue'
import ObjectList from '@/components/calendar_v2/ObjectList.vue'
import Loading from '@/components/calendar_v2/Loading.vue'
import DateTimePicker from '@/components/calendar_v2/DateTimePicker.vue'
import calendarService from '@/services/cal/calendarService.js'
import AddressPicturesDialog from '@/components/crm/addresses/AddressPicturesDialog.vue'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import ReservedDataDialog from '@/components/calendar_v2/ReservedDataDialog.vue'
import SerialAppointmentDialog from '@/components/calendar_v2/SerialAppointmentDialog.vue'
import stompService from '../../services/stomp/stomp.service'
import SerialReservedDataDialog from './SerialReservedDataDialog.vue'
import moment from 'moment'

export default {
  computed: {
    ...mapState({
      scopes: state => state.calendarInformation.scopes,
      locations: state => state.calendarInformation.locations,
      types: state => state.calendarInformation.types,
      states: state => state.calendarInformation.states,
      addresses: state => state.calendarInformation.addresses,
      objects: state => state.calendarInformation.objects,
      resources: state => state.calendarInformation.resources
    }),
    currentDisplay() {
      return this.$vuetify.breakpoint.name
    }
  },
  components: {
    //NameSearchHelpDialog,
    AddressSearch,
    Loading,
    ObjectList,
    DateTimePicker,
    // eslint-disable-next-line vue/no-unused-components
    calendarService,
    AddressPicturesDialog,
    ErrorDialog,
    ReservedDataDialog,
    SerialAppointmentDialog,
    SerialReservedDataDialog
  },
  data: () => ({
    editAppointment: false,
    appointment: [],
    days: Array.from({ length: 30 }, (_, i) => i),
    hours: Array.from({ length: 24 }, (_, i) => i),
    minutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    durationSplit: [],
    startPicker: true,
    startDate: '',
    endDate: '',
    startPickerMenu: false,
    endPickerMenu: false,
    searchText: '',
    dateTimeService,
    endDateString: '',
    startDateString: '',
    expansionPanelModel: [0],
    minimumDate: new Date('01.01.1970'),
    maximumDate: new Date('01.01.2123'),
    preventSave: false,
    type: '',
    showingDefDuration: false,
    startDateTimeFromMouseClick: '',
    category: [],
    formValid: false,
    rules: {
      required: [value => !!value || 'Pflichtfeld']
    },
    currentAddress: [],
    showAddressPicturesDialog: false,
    failMessage: 'Pflichtfelder ausfüllen',
    errorDialog: false,
    isSerial: false
  }),
  methods: {
    async lockAppointment() {
      if (!stompService.connected) {
        await stompService.connect()
      }
      stompService.send('/app/cal/appointment/edit/opened', this.appointment)
    },
    async unlockAppointment() {
      if (!stompService.connected) {
        await stompService.connect()
      }
      stompService.send('/app/cal/appointment/edit/closed', this.appointment)
    },
    closeSerialReservedDataDialog() {
      this.$refs.reservedSerial.serialReservedDataDialog = false
      this.editAppointment = false
    },
    open(type, date = '', category = '', isSerial = false) {
      this.isSerial = isSerial
      this.type = type
      if (type == 'edit') {
        console.warn('Nun wird editiert!')
        this.lockAppointment()
        this.durationSplit = dateTimeService.getDurationSplit(
          this.appointment.duration
        )
        this.startDate = dateTimeService.getDateStringFromDateTimeString(
          this.appointment.start
        )
        this.endDate = dateTimeService.getDateStringFromDateTimeString(
          this.appointment.end
        )

        this.maximumDate = new Date(this.appointment.end).toISOString()
        this.minimumDate = new Date(this.appointment.start).toISOString()
        this.recalculateDateTime('init')
        this.showingDefDuration = false
        this.searchText = ''
      } else if (type == 'add' && date === '') {
        this.recalculateDateTime('addInit')
      } else if (type == 'add' && date != '' && category === '') {
        this.startDateTimeFromMouseClick = date
        this.recalculateDateTime('addInitWithStartDate')
      } else if (type == 'add' && date != '' && category != '') {
        console.warn('ja - nein?')
        this.startDateTimeFromMouseClick = date
        this.category = category
        this.recalculateDateTime('addInitWithStartDateAndCategory')
      } else if (type == 'show') {
        this.durationSplit = dateTimeService.getDurationSplit(
          this.appointment.duration
        )
        this.startDate = dateTimeService.getDateStringFromDateTimeString(
          this.appointment.start
        )
        this.endDate = dateTimeService.getDateStringFromDateTimeString(
          this.appointment.end
        )
        this.recalculateDateTime('init')
      }

      this.editAppointment = true
    },
    close() {
      this.editAppointment = false
    },
    edit(reservedAppointment = null, option = null) {
      if (this.$refs.form.validate()) {
        if (this.isSerial) {
          calendarService
            .updateSerialAppointment(this.appointment)
            .catch(error => {
              console.log(error)
              if (error.response.status == 409) {
                console.log('Reserved')
                this.$refs.reservedSerial.open(error.response.data, 'edit')
              }
            })
            .then(response => {
              if (response.status >= 200 && response.status < 300) {
                this.editAppointment = false
                this.$toast.success('Terminserie wurde gespeichert')
              }
            })
        } else {
          if (reservedAppointment === null && option === null) {
            calendarService
              .updateAppointment(this.appointment)
              .catch(reserved => {
                console.log('Reserved')
                this.$refs.reserved.open(reserved, 'edit')
              })
              .then(() => {
                if (this.$refs.reserved.reservedDataDialog == false) {
                  this.editAppointment = false
                  this.$toast.success('Termin wurde gespeichert')
                }
              })
          } else {
            reservedAppointment.conflictResolution = option
            calendarService.updateAppointment(reservedAppointment).then(() => {
              this.editAppointment = false
              this.$toast.success('Termin wurde gespeichert')
            })
          }
        }
      } else {
        this.errorDialog = true
      }
    },
    add(reservedAppointment = null, option = null) {
      if (this.$refs.form.validate()) {
        if (reservedAppointment === null && option === null) {
          calendarService
            .addAppointment(this.appointment)
            .catch(reserved => {
              this.$refs.reserved.open(reserved, 'add')
            })
            .then(() => {
              console.warn(this.appointment)
              if (this.$refs.reserved.reservedDataDialog == false) {
                this.editAppointment = false
                this.$toast.success('Termin wurde erstellt')
              }
            })
        } else {
          reservedAppointment.conflictResolution = option
          calendarService.addAppointment(reservedAppointment).then(() => {
            console.warn(reservedAppointment)
            this.editAppointment = false
            this.$toast.success('Termin wurde erstellt')
          })
        }
      } else {
        this.errorDialog = true
      }
    },
    searchAddresses() {
      this.$refs.loading.open('Adressen werden geladen ...')
      this.$store.dispatch('loadAddresses', this.searchText).then(() => {
        this.appointment.addresses.forEach(element => {
          const i = this.addresses.findIndex(
            a => a.key.lfdnr === element.key.lfdnr
          )
          if (i > -1) {
            this.addresses.splice(i, 1)
          }
        })
        this.$refs.loading.close()
        this.$refs.addressSearch.open(this.addresses)
      })
    },
    addAddress(item) {
      if (!this.appointment.addresses.includes(item)) {
        this.appointment.addresses.push(item)
      }
    },
    addObject(item) {
      if (!this.appointment.objects.includes(item)) {
        this.appointment.objects.push(item)
      }
    },
    removeAddress(item) {
      this.appointment.addresses.splice(
        this.appointment.addresses.indexOf(item),
        1
      )
    },
    removeObject(item) {
      this.appointment.objects.splice(this.appointment.objects.indexOf(item), 1)
    },
    openObjectList() {
      this.$refs.loading.open('Objekte werden geladen...')
      this.$store.dispatch('loadObjects', this.appointment).then(() => {
        this.appointment.objects.forEach(element => {
          const i = this.objects.findIndex(a => a.key.code === element.key.code)
          if (i > -1) {
            this.objects.splice(i, 1)
          }
        })
        this.$refs.loading.close()
        this.$refs.objectList.open(this.objects)
      })
    },
    openDateTimePicker(type) {
      if (type === 'start') {
        this.$refs.startPicker.date = dateTimeService.getDateStringFromDateTimeString(
          this.appointment.start
        )
        if (!this.appointment.wholeDay) {
          this.$refs.startPicker.time = dateTimeService.getTimeStringFromDateTimeString(
            this.appointment.start
          )
        }

        this.$refs.startPicker.minimumDate = this.minimumDate
        this.$refs.startPicker.maximumDate = this.maximumDate

        this.$refs.startPicker.mode = 'start'
        this.$refs.startPicker.open(this.appointment.allDay)
      }
      if (type === 'end') {
        this.$refs.endPicker.date = dateTimeService.getDateStringFromDateTimeString(
          this.appointment.end
        )
        if (!this.appointment.wholeDay) {
          this.$refs.endPicker.time = dateTimeService.getTimeStringFromDateTimeString(
            this.appointment.end
          )
        }
        this.$refs.endPicker.minimumDate = this.minimumDate.slice(0, 10)
        this.$refs.endPicker.maximumDate = this.maximumDate.slice(0, 10)

        this.$refs.endPicker.mode = 'end'
        this.$refs.endPicker.open(this.appointment.allDay)
      }
    },
    saveStart(date, time) {
      if (time != null) {
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(
          new Date(date + ' ' + time)
        )
      } else {
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(
          new Date(date)
        )
      }
      this.minimumDate = new Date(this.appointment.start).toISOString()
      this.recalculateDateTime('start')
    },
    saveEnd(date, time) {
      if (time != null) {
        this.appointment.end = dateTimeService.getDateTimeStringFromDate(
          new Date(date + ' ' + time)
        )
      } else {
        this.appointment.end = dateTimeService.getDateTimeStringFromDate(
          new Date(date)
        )
      }

      this.maximumDate = new Date(this.appointment.end).toISOString()
      this.recalculateDateTime('end')
    },
    recalculateDateTime(elem) {
      if (elem == 'allDay') {
        this.durationSplit.days = dateTimeService.daysBetween(
          new Date(this.appointment.start),
          new Date(this.appointment.end)
        )
        this.durationSplit.days++
        this.durationSplit.hours = 0
        this.durationSplit.minutes = 0
      }
      if (elem == 'duration' || elem == 'start') {
        this.appointment.end = dateTimeService.getDateTimeStringFromDate(
          dateTimeService.addTimeToDate(
            this.appointment.start,
            this.durationSplit.days,
            this.durationSplit.hours,
            this.durationSplit.minutes
          )
        )
      } else if (elem == 'end') {
        console.log(this.durationSplit)
        this.durationSplit = dateTimeService.getDaysHoursMinutesFromDateDifference(
          new Date(this.appointment.start),
          new Date(this.appointment.end)
        )
        console.log(this.durationSplit)
      }
      if (this.appointment.allDay == true) {
        moment.locale('de')
        this.endDateString = moment(this.appointment.end).format('lll')
        this.startDateString = moment(this.appointment.start).format('lll')
      } else if (elem == 'addInit') {
        var coeff = 1000 * 60 * 5
        var date = new Date()
        var currentDate = new Date(Math.round(date.getTime() / coeff) * coeff)
        //heutiges Datum und Uhrzeit (gerundet auf 5min) wird als Start angegeben
        this.startDateString = dateTimeService.getGermanDateFormat(currentDate)
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(
          currentDate
        )
        //Standarddauer: 15 min
        this.appointment.duration = 15
        this.durationSplit = dateTimeService.getDurationSplit(
          this.appointment.duration
        )
        this.recalculateDateTime('duration')
        this.maximumDate = new Date(this.appointment.end).toISOString()
        this.minimumDate = new Date(this.appointment.start).toISOString()
      } else if (elem == 'addInitWithStartDate') {
        this.startDateString = dateTimeService.getGermanDateFormat(
          this.startDateTimeFromMouseClick
        )
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(
          this.startDateTimeFromMouseClick
        )
        this.appointment.duration = 15
        this.durationSplit = dateTimeService.getDurationSplit(
          this.appointment.duration
        )
        this.recalculateDateTime('duration')
        this.maximumDate = new Date(this.appointment.end).toISOString()
        this.minimumDate = new Date(this.appointment.start).toISOString()
      } else if (elem == 'addInitWithStartDateAndCategory') {
        this.startDateString = dateTimeService.getGermanDateFormat(
          this.startDateTimeFromMouseClick
        )
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(
          this.startDateTimeFromMouseClick
        )
        this.appointment.duration = 15
        this.durationSplit = dateTimeService.getDurationSplit(
          this.appointment.duration
        )
        this.recalculateDateTime('duration')
        this.maximumDate = new Date(this.appointment.end).toISOString()
        this.minimumDate = new Date(this.appointment.start).toISOString()
        console.warn(this.category)
        console.warn('y')
        switch (this.category.type) {
          case 'scope':
            this.appointment.scope = this.category.object
            break
          case 'location':
            this.appointment.location = this.category.object
            break
        }
      } else {
        console.warn('hi')
        moment.locale('de')
        this.endDateString = moment(this.appointment.end).format('lll')
        this.startDateString = moment(this.appointment.start).format('lll')
      }
    },
    setDefDuration() {
      this.durationSplit = dateTimeService.getDurationSplit(
        this.appointment.type.defDuration
      )
      this.recalculateDateTime('duration')
      this.showingDefDuration = false
    },
    clearData() {
      console.warn('hey')
      this.appointment = {
        addresses: [],
        objects: [],
        resources: [],
        key: {
          firma: 1
        },
        scope: this.scopes[0],
        location: this.locations[0],
        type: this.types[0],
        state: this.states[0]
      }

      this.showingDefDuration = false
      this.searchText = ''
    },
    showPictures(item) {
      this.currentAddress = item
      console.warn(this.currentAddress)
      this.showAddressPicturesDialog = true
    },
    closeAddressPicturesDialog() {
      this.showAddressPicturesDialog = false
    },
    openSerialAppointmentDialog() {
      if (this.$refs.form.validate()) this.$refs.serial.open(this.appointment)
      else this.errorDialog = true
    }
  }
}
</script>

<style scoped></style>
