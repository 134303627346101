<template>
  <div>
    <v-app-bar app color="white lighten-5" class="elevation-0">
      <!--
      <h2
          style="
          font-size: 2.4em;
          gap: 0px;
        "
        class="primary--text"
        v-if="this.$route.meta.displayName != 'Login'"
      >
        <div v-if="!isMobile()">
          {{
            this.$route.meta.displayName !== undefined
                ? this.$route.meta.displayName
                : this.$route.name
          }}
        </div>
        <div v-else>
          {{
            this.$route.meta.displayName !== undefined
                ? this.$route.meta.displayName.substring(0, 8)
                : this.$route.name.substring(0, 8)
          }}<span v-if="this.$route.meta.displayName.length > 8">...</span>
        </div>
      </h2>

      <--Search Bar-->

      <v-app-bar-nav-icon
        @click="openDrawer"
        v-if="loggedIn && miniNavbar"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <!--
      <v-app-bar-nav-icon v-if="!showSearch" @click="expandSearchBar"> 
        <v-icon v-if="!showSearch">
          mdi-magnify
        </v-icon>
      </v-app-bar-nav-icon>
    -->

      <div class="mt-10 d-flex align-items-center">
        <SearchComponent v-if="searchBarVisible" class="mr-2"></SearchComponent>

        <!--
          <v-app-bar-nav-icon v-if="showSearch" @click="expandSearchBar"> 
            <v-icon v-if="showSearch">
              mdi-close
            </v-icon>
          </v-app-bar-nav-icon>
          -->
      </div>

      <NotificationComponent v-if="loggedIn" />

      <v-menu v-if="loggedIn" offset-y min-height="800px">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="primary rounded-pill fluid"
            style="
              display: grid;
              place-items: center;
              grid-template-columns: 2fr 0fr;
            "
          >
            <span
              v-if="!isMobile()"
              class="text-h6 font-weight-bold text-center white--text ml-2 px-4"
            >
              {{ user.username }}
            </span>
            <v-avatar class="elevation-0"
              ><img :src="user_img_base64" alt="Profilbild"
            /></v-avatar>
          </div>
        </template>
        <div class="white px-4" style="height: 330px">
          <v-card-title class="headline justify-center pt-8">
            <v-avatar size="100px">
              <img :src="user_img_base64" alt="Profilbild" />
            </v-avatar>
          </v-card-title>
          <v-card-subtitle class="mt-4 text-h5 font-weight-bold text-center">
            {{ user.username }}
          </v-card-subtitle>
          <v-card-text class="text-center text-h6 grey--text text--darken-3"
            >{{ user.email }}
          </v-card-text>
          <v-card-actions class="justify-center mb-4">
            <v-btn
              color="primary darken-1"
              class="rounded-pill px-8"
              text
              outlined
              @click="logout"
            >
              Abmelden
            </v-btn>
          </v-card-actions>
        </div>
      </v-menu>

      <v-btn
        elevation="2"
        large
        v-if="loginButtonVisible"
        :to="{ name: 'Login' }"
        >Login</v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
import NotificationComponent from '@/components/core/NotificationComponent.vue'
import { mapGetters } from 'vuex'
import SearchComponent from '@/components/home/SearchComponent.vue'

export default {
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  components: {
    NotificationComponent,
    SearchComponent
  },
  computed: {
    ...mapGetters(['loggedIn', 'user', 'user_img_base64']),
    loginButtonVisible() {
      if (!this.loggedIn && this.$route.name != 'Login') {
        return true
      }
      return false
    },
    searchBarVisible() {
      if (
        this.$route.name == 'Home' ||
        this.$route.name == 'Login' ||
        !this.loggedIn
      ) {
        return false
      } else {
        return true
      }
    },

    miniNavbar() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm' ||
        this.$vuetify.breakpoint.name == 'md'
      ) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    drawer(newVal) {
      this.$emit('drawerChange', newVal)
    }
  },
  methods: {
    openDrawer() {
      this.$emit('drawerChange', true)
    },
    isMobile() {
      if (this.$vuetify.breakpoint.name == 'xs') {
        return true
      } else {
        return false
      }
    },
    logout() {
      this.$store.dispatch('logout')
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true
      }
      return false
    },
    navigateToHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style></style>
