<template>
  <v-list>
    <v-list-item
      v-for="action in interactions"
      :key="action.mDat"
      link
      :to="{
        name: 'crmInteractions',
        params: { lfdnr: action.id.kdnr }
      }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>
          mdi-account-voice
        </v-icon>
      </v-list-item-icon>
      <v-divider vertical class="mr-4 my-2"></v-divider>

      <v-list-item-content>
        <v-list-item-title class="d-flex justify-space-between">
          <span>{{ action.keywords }}</span>
        </v-list-item-title>
        <v-list-item-subtitle class="d-flex justify-space-between">
          <span>{{ action.kdSortname }}</span>
          <span>{{
            dateTimeService.getGermanDateFormatWithoutTime(action.date)
          }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import axios from 'axios'
import dateTimeService from '@/services/cal/dateTimeService'

export default {
  data: function() {
    return {
      interactions: [],
      dateTimeService
    }
  },
  methods: {
    loadDocumentsLast() {
      axios.get('/v1/dashboard/interactions/last').then(res => {
        this.interactions = res.data
      })
    }
  },

  mounted() {
    this.loadDocumentsLast()
  }
}
</script>

<style></style>
