<template>
  <v-menu
    :value="showNotificationsMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    :max-width="getMenuSize()"
    max-height="75%"
    left
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <div
          v-bind="attrs"
          v-on="on"
          class="rounded-pill fluid mx-3 pa-2"
          @click="askForNotificationPermission()"
          :class="{ hoveredIcon: hover }"
        >
          <v-icon v-if="getAmountOfUnreadNotifications() == 0">mdi-bell</v-icon>
          <v-badge
            color="orange"
            :content="
              getAmountOfUnreadNotifications() > 9
                ? '9+'
                : getAmountOfUnreadNotifications()
            "
            overlap
            v-else
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <!--<v-icon v-else>mdi-bell-badge</v-icon>-->
        </div>
      </v-hover>
    </template>

    <v-card class="notification-container menu">
      <v-row
        v-if="
          this.waitForAnswerOfNotificationPermission == false &&
            getAmountOfUnreadNotifications() != 0
        "
      >
        <v-col
          cols="12"
          class="my-2 p-2 mb-n4 d-flex justify-center font-weight-black"
        >
          {{ getAmountOfUnreadNotifications() }} ungelesene Nachrichten
        </v-col>
        <v-container>
          <div
            v-for="notification in this.$store.state.notifications
              .notifications"
            :key="notification.nId"
          >
            <v-hover v-slot="{ hover }" v-if="notification.read == false">
              <v-col cols="12" class="pa-0 ma-0">
                <v-divider :inset="notification.inset"></v-divider>

                <v-col
                  @click="openNotification(notification)"
                  cols="12"
                  class="px-6 mx-lg-auto"
                  :class="{
                    selectedNotification: hover
                  }"
                >
                  <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0" cols="10">
                      <v-col class="pa-0 ma-0 font-weight-black">
                        {{ notification.subject }}
                      </v-col>
                      <v-col
                        class="pa-0 ma-0 mb-1 text--secondary font-weight-black"
                        style="font-size: 12px"
                      >
                        {{ notification.a_dat }} | {{ notification.fromUserId }}
                      </v-col>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="2" align="right">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          @click.stop.prevent
                          @click="setNotificationToRead(notification)"
                          fab
                          small
                          plain
                          :class="{ hoveredIcon: hover }"
                        >
                          <v-icon> mdi-email-check </v-icon>
                        </v-btn>
                      </v-hover>
                    </v-col>
                  </v-row>
                  <v-col class="pa-0 ma-0">{{ notification.message }}</v-col>
                </v-col>
              </v-col>
            </v-hover>
          </div>
        </v-container>
      </v-row>
      <v-row
        v-if="
          this.waitForAnswerOfNotificationPermission == true ||
            getAmountOfUnreadNotifications() == 0
        "
      >
        <v-col class="mx-6 mt-4 mb-0">
          <v-icon class="d-flex justify-content-center" size="80">
            mdi-bell
          </v-icon>
          <v-col class="d-flex justify-content-center text-center">
            {{ getErrorMessage() }}
          </v-col>
        </v-col>
      </v-row>
      <v-col align="center">
        <v-btn
          @click="openNotificationTable()"
          outlined
          v-if="!this.waitForAnswerOfNotificationPermission"
        >
          {{ getButtonText() }}
        </v-btn>
      </v-col>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    waitForAnswerOfNotificationPermission: false,
    showNotificationsMenu: false
  }),
  mounted() {
    this.$store.dispatch('GET_ALL_NOTIFICATIONS')

    this.$OneSignal.on('notificationDisplay', () => {
      this.$store.dispatch('GET_ALL_NOTIFICATIONS')
    })
  },
  methods: {
    setNotificationToRead(notification) {
      if (notification.read == false) {
        this.$store.dispatch(
          'CHANGE_READ_STATUS_NOTIFICATION',
          notification.nId
        )
      }
    },
    //Macht das Notificationfenster kleiner wenn Bildschirmwidth zu klein ist
    getMenuSize() {
      if (window.innerWidth > 420) {
        return 400
      } else {
        return 340
      }
    },
    getButtonText() {
      if (window.innerWidth > 420) {
        return 'Alle Benachrichtigungen anzeigen'
      } else {
        return 'Alle Anzeigen'
      }
    },
    getErrorMessage() {
      if (this.waitForAnswerOfNotificationPermission == true) {
        return 'Anfordern der Browserberechtigungen zum Anzeigen von Nachrichten.'
      } else {
        return 'Sie haben keine neuen Nachrichten erhalten.'
      }
    },
    getAmountOfUnreadNotifications() {
      let unread = this.$store.state.notifications.notifications.filter(
        notification => notification.read == false
      )
      return unread.length
    },
    openNotification(notification) {
      this.showNotificationsMenu = false
      this.$router.push({
        name: 'Notification',
        query: { nId: notification.nId }
      })
    },
    askForNotificationPermission() {
      if (!('Notification' in window)) {
        alert('This browser does not support desktop notification')
      } else if (
        Notification.permission !== 'denied' &&
        Notification.permission !== 'granted'
      ) {
        this.$OneSignal.showNativePrompt().then(() => {
          this.waitForAnswerOfNotificationPermission =
            Notification.permission !== 'denied' &&
            Notification.permission !== 'granted'
        })
      }
      this.waitForAnswerOfNotificationPermission =
        Notification.permission !== 'denied' &&
        Notification.permission !== 'granted'
      this.showNotificationsMenu = true
    },
    //Öffnet Notification Übersicht
    openNotificationTable() {
      this.$router.push({
        name: 'Notification'
      })
      this.showNotificationsMenu = false
    }
  }
}
</script>

<style>
.selectedNotification {
  background-color: rgb(230, 230, 230) !important;
  cursor: pointer;
}
.hoveredIcon {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
