<template>
  <v-container>
    <v-card elevation="0" class="rounded-xl">
      <v-card-title
        class="justify-center mx-auto ma-4 "
        style="max-width: 450px"
      >
        <v-img
          class="mt-8"
          src="@/assets/svg/undraw_page_not_found_re_e9o6.svg"
          max-width="350px"
        ></v-img>
      </v-card-title>
      <v-card-subtitle class="text-h4 text-center black--text mt-4 pb-4"
        ><h3 class="my-4">Fehler 404</h3>
        <span class="text-h5">Seite nicht gefunden.</span></v-card-subtitle
      >
      <v-card-actions class="d-flex justify-center pb-4">
        <v-btn
          @click="routeToHome"
          color="white"
          rounded
          class="px-8 py-4"
          elevation="1"
        >
          Zurück auf die Homepage
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  methods: {
    routeToHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style></style>
