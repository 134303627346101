import axios from 'axios'

export default {
  state: {
    logins: {}
  },
  mutations: {
    SET_TELEMETRY_LOGINS(state, newState) {
      state.logins = Array.from(newState)
    }
  },
  actions: {
    loadTelemetryLogins({ commit }) {
      return axios
        .get('/v1/dashboard/telemetry/failedLogins')
        .then(response => {
          commit('SET_TELEMETRY_LOGINS', response.data)
        })
    }
  },
  getters: {
    telemetryLogins(state) {
      return state.logins
    }
  }
}
