<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-header
    hide-default-footer
    :items-per-page="items.length"
    :search="search"
  >
    <template v-slot:top>
      <v-text-field
        outlined
        dense
        class="mb-n5"
        label="Suche"
        v-model="search"
      ></v-text-field>
    </template>
    <template v-slot:item.betrag="{ item }">
      {{ format(item.betrag) }}</template
    >
  </v-data-table>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    items: [],
    headers: [
      { text: 'Jahr / Monat', value: 'monatJahr', align: 'start' },
      { text: 'Offene Summe', value: 'betrag', align: 'end' }
    ],
    search: ''
  }),
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      axios.get('v1/dashboard/openPositions').then(data => {
        this.items = data.data
      })
    },
    format(value) {
      var form = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'EUR'
      })

      return form.format(value)
    }
  }
}
</script>

<style></style>
