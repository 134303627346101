<template>
  <v-dialog v-model="DateTimePicker" scrollable max-width="367px">
    <v-card flat>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar
          color="primary"
          class="mx-0 px-0 my-0 py-0 elevation-0"
          flat
          fixed
          top
          dark
        >
          <v-icon @click="DateTimePicker = false">mdi-close</v-icon>
          <!--Überschrift-->
          <v-toolbar-title class="flex text-center"
            >Datum und Uhrzeit wählen</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>

      <v-tabs fixed-tabs v-model="tab" icons-and-text>
        <v-tab default key="date"
          >{{ dateString }}<v-icon>mdi-calendar-range</v-icon>
        </v-tab>
        <v-tab :disabled="allDay" key="time"
          >{{ time }} <v-icon>mdi-clock</v-icon></v-tab
        >
      </v-tabs>
      <v-card-text class="mx-0 px-0 my-3 py-0" justify-center align="center">
        <v-tabs-items v-model="tab">
          <v-tab-item key="date">
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              first-day-of-week="1"
              color="primary"
              :min="minimumDate"
              @change="goToTime()"
            >
            </v-date-picker
          ></v-tab-item>
          <v-tab-item key="time">
            <vue-timepicker></vue-timepicker>
            <v-row align="center" justify="center">
              <v-col>
                <v-text-field
                  maxlength="5"
                  v-model="time"
                  type="time"
                  step="300"
                  style="width: 55px"
                  class="text-justify text-center"
                  @change="steptime(this)"
                ></v-text-field>
                <v-alert
                  type="warning"
                  style="margin-left: 10px; margin-right: 10px"
                  v-if="blockSave"
                >
                  Die Uhrzeit darf nur in 5 Minuten Schritten gespeichert
                  werden.
                  <v-row>
                    <v-col>
                      <v-btn @click="setRoundDown">
                        {{ roundToNearestFiveMinutes(this.time, 'down') }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn @click="setRoundUp">
                        {{ roundToNearestFiveMinutes(this.time, 'up') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
            <v-time-picker
              v-model="time"
              :allowed-minutes="allowedStep"
              format="24hr"
              color="primary"
              no-title
            ></v-time-picker>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="save()">Fertig</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService.js'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    dateTimeService
  },
  data: () => ({
    DateTimePicker: false,
    allDay: null,
    tab: 0,
    date: '',
    time: '',
    minimumDate: '',
    maximumDate: '',
    mode: '',
    dateString: '',
    hours: '',
    minutes: '',
    inputHour: '', // Eingabe-Stunden
    inputMinute: '', // Eingabe-Minuten
    isoTime: '', // ISO-Zeitstempel HH:mm
    blockSave: false,
    roundDownTime: '',
    roundUpTime: '',
    allowedStep: m => m % 5 === 0,
    hourRules: [
      v => /^\d{1,2}$/.test(v) || 'Ungültig',
      v => (parseInt(v) >= 0 && parseInt(v) <= 23) || 'Ungültig'
    ],
    minuteRules: [
      v => /^\d{1,2}$/.test(v) || 'Ungültig',
      v => (parseInt(v) >= 0 && parseInt(v) <= 59) || 'Ungültig'
    ]
  }),
  methods: {
    open(allDay) {
      this.tab = 0
      this.allDay = allDay
      this.DateTimePicker = true
      this.dateString = new Date(this.date).toLocaleString('de-DE', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })
      if (allDay) {
        this.time = ''
      }
      if (this.mode == 'end') {
        this.maximumDate = undefined
      } else if (this.mode == 'start') {
        this.minimumDate = undefined
      }
      console.warn(this.minimumDate)
      console.warn(this.maximumDate)
    },
    save() {
      const timeRegex = /^(?:[01]\d|2[0-3]):(?:0[05]|1[05]|2[05]|3[05]|4[05]|5[05])$/gm
      let test = timeRegex.test(this.time)
      if (!test) {
        console.log(this.time)
        this.blockSave = true
        return
      } else {
        this.blockSave = false
      }
      this.DateTimePicker = false

      if (this.allDay) {
        this.$emit('save', this.date, null)
      } else {
        this.$emit('save', this.date, this.time)
      }

      console.log(this.date, this.time)
    },
    goToTime() {
      if (!this.allDay) this.tab = 1
    },
    updateTimeStr() {
      this.time = this.hours + ':' + this.minutes
    },
    roundToNearestFiveMinutes(timeString, roundingMode = 'up') {
      // Parse the input time string to get hours and minutes
      const [hours, minutes] = timeString.split(':').map(Number)

      // Calculate the remainder when dividing minutes by 5
      const remainder = minutes % 5

      // If remainder is 0, the time is already a multiple of 5, so return it as is
      if (remainder === 0) {
        return timeString
      }

      // Calculate the minutes to add or subtract to reach the nearest multiple of 5
      let minutesAdjustment = 0
      if (roundingMode === 'up') {
        minutesAdjustment = 5 - remainder
      } else if (roundingMode === 'down') {
        minutesAdjustment = -remainder
      }

      // Calculate the rounded time
      const roundedMinutes = (minutes + minutesAdjustment + 60) % 60
      const roundedHours =
        (hours + Math.floor((minutes + minutesAdjustment) / 60) + 24) % 24

      // Pad the hours and minutes with leading zeros if necessary
      const roundedHoursString = String(roundedHours).padStart(2, '0')
      const roundedMinutesString = String(roundedMinutes).padStart(2, '0')

      // Return the rounded time in HH:mm format
      return `${roundedHoursString}:${roundedMinutesString}`
    },
    setRoundDown() {
      this.time = this.roundToNearestFiveMinutes(this.time, 'down')
      this.blockSave = false
    },
    setRoundUp() {
      this.time = this.roundToNearestFiveMinutes(this.time, 'up')
      this.blockSave = false
    }
  }
}
</script>

<style scoped>
.time-field {
  font-size: 40rem !important;
  text-align: center !important;
}

.v-text-field__slot > input {
  font-size: 400px !important;
}

.time-colon {
  font-size: 2.5rem !important;
  width: 10px !important;
  text-align: center !important;
}
</style>
