import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from 'vue-toastification'
import VueViewer from 'v-viewer'
import setupInterceptors from './services/axios/setupInterceptors'
import './plugins/leaflet'
import './plugins/wysiwyg-editor'
import './plugins/apexcharts'
import './registerServiceWorker'
import 'vue-toastification/dist/index.css'
import 'viewerjs/dist/viewer.css'
import OneSignalVue from 'onesignal-vue'
import VueScrollactive from 'vue-scrollactive'
//import NotificationService from '@/services/onesignal/onesignal'
import VueSmartWidget from 'vue-smart-widget'
import VueGridLayout from 'vue-grid-layout'

Vue.config.productionTip = false

setupInterceptors(store)
Vue.use(VueScrollactive)
Vue.use(VueViewer)
Vue.use(VueSmartWidget)
Vue.use(VueGridLayout)

// The magic is here
import './App.scss'

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
})
Vue.use(OneSignalVue)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {}
}).$mount('#app')
